<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
    name: 'weekly-quota-tracker',
    props: {
        isInline: {
            type: [Boolean],
            default: false
        },
        isInsideResearcherTracker: {
            type: [Boolean],
            default: false
        }
    },
    components: {
        CongratulatoryModal: () => import("@/components/layouts/modals/CongratulatoryModal"),
    },
    computed: {
        ...mapState(['weekly_quota', 'currentAssignedCampaign']),
        computedProgress() {
            return this.weekly_quota.target != null && this.weekly_quota.target != 0 ? (this.weekly_quota.current / this.weekly_quota.target) * 100 : 0
        },
        wavePositionTop() {
            return `top: ${(-this.computedProgress) + 65 - (this.computedProgress >= 100 ? 100 : 0)}%`
        },
        user() {
            return JSON.parse(localStorage.getItem('ver'))
        },
        parent() {
            let p = this.$parent
            let count = 0

            while (p.$vnode.componentOptions.tag != 'ar-master' && count < 6) {
                p = p['$parent']
                count++
            }

            return p
        },
        isResearcher() {
            return this.user?.access_level == 1
        },
        dateToBeApplied() {
            return moment().add(1, 'weeks').isoWeekday(1);
        },
        pendingUpdates() {
            const user = this.weekly_quota.info
            const pending_updates = [...(user?.weekly_quota?.pending_updates ?? []), ...(user?.weekly_quota_group?.pending_updates ?? [])]
            return pending_updates?.sort((a, b) => new Date(a.updated_at).getTime() < new Date(b.updated_at).getTime() ? 1 : -1)
        },
        pendingUpdatesFrom() {
            return this.weekly_quota.info?.weekly_quota?.status == 0
                ? "DISABLED"
                : this.weekly_quota.target
        },
        pendingUpdateTo() {
            const pendingUpdates = this.pendingUpdates[0] ?? null
            
            if (!pendingUpdates)
                return 0
        
            const to = pendingUpdates?.target_type == 'status' && pendingUpdates?.target_value == '0'
                ? "DISABLED"
                : pendingUpdates?.target_type == 'target' 
                    ? pendingUpdates.target_value 
                    : pendingUpdates.target_value == 0
                        ? 0
                        : !this.pendingUpdates.filter(p => p.target_type == 'target').length
                            ? this.weekly_quota.target
                            : this.pendingUpdates.filter(p => p.target_type == 'target')[0].target_value

            return to
        }
    },
    data() {
        return {
            hovered: false
        }
    },
    methods: {
        showCongratulatoryModal() {
            $('#weekly-quota-congrats-modal').modal('show')
        }
    },
    watch: {
        'weekly_quota.current': function(newVal, oldVal) {
            if (this.computedProgress == 100) {
                this.showCongratulatoryModal()
            }
        }
    }
}
</script>

<template>
    <div class="weekly-quota-tracker" :class="[{'is-inline': isInline}]">
        <h3><img class="mr-1" src="/static/svg/researched-tracker/tracker-button.svg">My Weekly Progress</h3>

        <div class="weekly-quota-tracker-container">
            <div class="weekly-target animated" :class="[{'tada': computedProgress >= 100}]" @mouseenter="hovered = true" @mouseleave="hovered = false" :style="''">
                <div v-if="computedProgress < 100" class="some-circle"></div>
                <img v-else src="/static/svg/researched-tracker/campaign-bg.svg" class="campaign-magic-magic completed-magic">
                <!-- <a href="javascript:void(0)" class="track-expand">
                    <img src="/static/svg/weekly-quota/expand_1.svg" />
                </a> -->

                <h5 class="some-label mt-3" :class="[{'filled': computedProgress >= 70 && weekly_quota.target != null}]"><img :src="`/static/svg/${ computedProgress >= 90 && weekly_quota.target != null ? '' : 'weekly-quota/' }Mark_Researched.svg`" alt="some-icon"/> Weekly Target</h5>
                <h4 class="tracker-count" :class="[{'filled': computedProgress >= 30 && weekly_quota.target != null}]">{{ weekly_quota.current }}<small v-if="weekly_quota.target">/{{ weekly_quota.target }}</small></h4>
                <div class="some-progress">
                    <div class="wave" v-if="weekly_quota.target">
                        <div class="wave-1" :style="wavePositionTop"></div>
                        <div class="wave-2" :style="wavePositionTop"></div>
                    </div>
                </div>
            </div>
            <div class="campaign-platform d-flex align-items-center justify-content-center" :class="[{'is-researcher': isResearcher}]" @click="isResearcher ? $emit('auto-tracker-on-click') : null">
                <a href="javascript:void(0)" class="track-expand">
                    <img src="/static/svg/weekly-quota/expand_1.svg" />
                </a>

                <div class="d-flex justify-content-center align-items-center campaign">
                    <img src="/static/svg/researched-tracker/campaign-bg.svg" class="campaign-magic-magic">
                    <div class="image-container mr-2">
                        <img class="campaign-img" :src="parent.getImage('campaigns', currentAssignedCampaign?.campaign ?? 'Default')" alt="campaign-img" />
                    </div>
                    <h5 class="some-label campaign-name m-0">{{ currentAssignedCampaign?.campaign ?? 'No Campaign' }}</h5>
                </div>

                <div class="d-flex justify-content-center align-items-center p-1 platform">
                    <img class="platform-img mr-1" :src="parent.getImage('platforms', currentAssignedCampaign?.platform ?? 'Default')" alt="platform-img" />
                    <h5 class="some-label platform-name m-0">{{ currentAssignedCampaign?.platform ?? 'No Platform' }}</h5>
                </div>

                <div class="pending-quota-update pt-2 pb-3 pl-2 pr-2" :class="{'hovered': hovered || !isResearcher || isInsideResearcherTracker || $featureAccess.isRestricted('v-enable-auto-assignment'), 'filled': computedProgress >= 100}">
                    <div class="another-some-circle"></div>
                    <h5 class="some-label pq-label ml-1">Pending Quota Update!</h5>

                    <div class="applies-on mb-1">
                        <h5 class="mb-1">Applies on:</h5>
                        <p class="--data">{{ dateToBeApplied.format('DD MMMM YYYY') }}</p>
                    </div>

                    <div class="pending-target">
                        <h5 class="mb-1">Weekly Target:</h5>
                        <p class="--data">
                            <template v-if="pendingUpdates[0] ?? null">
                                Updated from <u class="text-invalid">{{ pendingUpdatesFrom }}</u> to <u class="text-success">{{ pendingUpdateTo }}</u>
                            </template>
                            <template v-else>
                                No New Updates!
                            </template>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <portal>
            <CongratulatoryModal id="weekly-quota-congrats-modal" congratulatoryImage="/static/svg/weekly-quota/Submitted - Completion.svg" congratulatoryMessage="Keep your success momentum going strong, continue in adding more listings to enhance your overall impact!"/>
        </portal>
    </div>
</template>

<style scoped>
h3 {
    font-size: 14px;
    font-weight: 700;
    color: #383A3D;
    text-transform: uppercase;
}

h3 img {
    position: relative;
    margin-top: -5px;
}

a.track-expand {
    color: #fff;
    display: block;
    position: absolute;
    top: 6px;
    right: 8px;
    width: 13px;
    height: 13px;
}

a.track-expand img {
    height: 100%;
    width: 100%;
}

.some-label {
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    -webkit-transition: .5s ease;
    transition: .5s ease;
}

.some-label.filled {
    color: #383A3D;
}

.weekly-quota-tracker {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    width: 323px;
    padding: 20px;
}

.weekly-quota-tracker.is-inline {
    width: 100%;
    padding: 10px;
    position: static;
}

.weekly-quota-tracker-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.weekly-target {
    width: 70%;
    border-radius: 14px;
    background: #649AD6;
    overflow: hidden;
    height: 120px;
    position: relative;
    z-index: 1;
    -webkit-transition: 3s ease-in-out;
    transition: 3s ease-in-out;
}

.some-circle {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
}

.some-circle::before {
    content: '';
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background: rgba(255,255,255,0.2);
    right: -34px;
    top: 15px;
    z-index: -1;
}

.weekly-target::before {
    content: '';
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background: rgba(255,255,255,0.2);
    right: -28px;
    top: -24px;
    z-index: -1;
}

.weekly-target .some-progress {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background: #34D934; */
    bottom: 0;
    z-index: -2;
    -webkit-transition: .8s ease;
    transition: .8s ease;
    max-height: 1000px;
    overflow: hidden;
}

.wave {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  background: #34D934;
  /* border-radius: 50%; */
  /* box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0.5); */
  z-index: -1;
}
.wave-1,
.wave-2{
  position: absolute;
  top: 0;
  left: 50%;
  width: 200%;
  height: 200%;
  background: black;
  transform: translate(-50%, -75%);
}
.wave .wave-1{
  border-radius: 45%;
  background: rgba(100, 154, 214, 1);
  /* background: rgba(255, 255, 255, 1); */
  animation: animate 5s linear infinite;
  -webki-transition: .5s ease;
  transition: .5s ease;
  position: absolute;
}
.wave .wave-2{
  border-radius: 40%;
  /* background: rgba(255, 255, 255, 0.5); */
  background: rgba(100, 154, 214, 0.5);
  animation: animate 10s linear infinite;
  -webki-transition: .5s ease;
  transition: .5s ease;
  position: absolute;
}

@keyframes animate{
  0%{
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100%{
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

.weekly-target .some-label, .tracker-count {
    margin-left: 17px;
}

.some-label img {
    height: 14px;
    width: 18px;
    display: block;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    margin-bottom: 13px;
}

.tracker-count {
    font-size: 32px;
    font-weight: 500;
    color: #FCFCFC;
    -webkit-transition: .5s ease;
    transition: .5s ease;
}

.tracker-count small {
    font-size: 13px;
    -webkit-transition: .2s ease;
    transition: .2s ease;
}

.tracker-count.filled, .tracker.filled small {
    color: #383A3D;
}

.weekly-target.tada .some-progress .wave {
    background: #FFD700;
}

.campaign-platform {
    height: 120px;
    background: #E87C7F;
    overflow: hidden;
    border-radius: 14px;
    width: 100%;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
}

.campaign-platform:is(.is-researcher) {
    cursor: pointer;
}

.campaign-platform:is(.is-researcher):hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.campaign-platform:is(.is-researcher):active {
    -webkit-transform: scale(.9);
    transform: scale(.9);
}

.campaign-platform::before {
    content: '';
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background: rgba(255,255,255,0.2);
    right: -2px;
    top: -13px;
    z-index: -1;
}

.campaign-platform::after {
    content: '';
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background: rgba(255,255,255,0.2);
    right: -24px;
    top: 15px;
    z-index: -1;
}

.campaign-magic-magic {
    position: absolute;
    height: 122px;
    width: 92px;
    z-index: -1;
    left: -9px;
    opacity: .3;
    top: -20.5px;
}

.campaign-platform:is(.is-researcher):hover .campaign-magic-magic {
    left: -10px;
    top: -21.5px;
    -webkit-transition: .1s ease;
    transition: .1s ease;
}
.campaign .image-container {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border: 2px solid #FCFCFC;
    border-radius: 100%;
}

.campaign-img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    height: 100%;
    width: 100%;
}

.campaign-name {
    width: 80px;
    font-size: 11px;
}

.platform {
    background: rgba(255,255,255,0.2);
    border-radius: 4px;
    margin-top: -36px;
}

.platform-img {
    height: 12px;
    width: 12px;
    display: inline-block;
}

.platform-name {
    text-transform: uppercase;
}

.pending-quota-update {
    background: #649AD6;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 100%;
    height: 100%;
    width: 100%;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
    border-radius: 14px;
}

.pending-quota-update.hovered {
    right: 0;
}

.pending-quota-update::before {
    content: '';
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background: rgba(255,255,255,0.2);
    left: -53px;
    top: -24px;
    z-index: -1;
}

.pending-quota-update::after {
    content: '';
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background: rgba(255,255,255,0.2);
    left: -45px;
    top: 15px;
    z-index: -1;
}

.pending-quota-update.filled {
    background: #FFD700 !important;
}

.pending-quota-update.filled h5 {
    color: #383A3D;
}

.another-some-circle {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.another-some-circle::before {
    content: '';
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background: rgba(255,255,255,0.2);
    left: -10px;
    top: -18px;
    z-index: -1;
}

.pq-label {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
}

.applies-on, .pending-target {
    padding: 6px 8px;
    background: rgba(255,255,255,0.2);
    border-radius: 14px;
}

.pending-target {
    padding: 6px 0;
}

.applies-on h5, .pending-target h5 {
    padding-left: 8px;
    font-size: 9px;
    color: #FFFFFF;
    font-weight: 400;
}

.--data {
    margin: 0;
    text-align: center;
}

.applies-on .--data {
    font-size: 11px;
    color: #383A3D;
    font-weight: 700;
}

.pending-target .--data {
    font-size: 10px;
    color: #383A3D;
    font-weight: 400;
    white-space: nowrap;
}

.text-success {
    font-weight: 700;
    color: #0C6D13 !important;
}

.text-invalid {
    font-weight: 700;
    color: #DC3C40 !important;
}

.completed-magic {
    right: -69px;
    left: initial;
    height: auto;
    width: 140px;
    top: -15px;
}
</style>