<template>
  <div class="sidebar" :style="getHeight()">
    <div>
        <template v-if="$route.name == 'lawfirmcounterfeitchecker'">
            <h4
            v-if="access_level() !== 6"
                id="filter-with-listing"
                style="cursor:pointer;"
                class="a-sidebar-filter-header d-flex justify-content-between align-items-center pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseWithListing"
                title="Click to show/hide with/without active data">
                <div>
                    Active Data
                </div>
                <i class="d-block text-muted fas fa-ellipsis-h"></i>
            </h4>
            <h4
            v-else-if="access_level() == 6 && campaignsList > 3"
                id="filter-with-listing"
                style="cursor:pointer;"
                class="a-sidebar-filter-header d-flex justify-content-between align-items-center pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseWithListing"
                title="Click to show/hide with/without active listing">
                <div>
                    Active Data
                </div>
                <i class="d-block text-muted fas fa-ellipsis-h"></i>
            </h4>

            <nav v-if="access_level() !== 6" class="mt-2 collapse show" id="collapseWithListing" aria-labelledby="filter-with-listing">
                <div style="min-height: 100px">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="listing-all"
                                    name="with-active" value="-1" v-model="withActive">
                                <label for="listing-all" class="custom-control-label">All</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="listing-with"
                                    name="with-active" value="1" v-model="withActive">
                                <label for="listing-with" class="custom-control-label">With</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="listing-without"
                                    name="with-active" value="0" v-model="withActive">
                                <label for="listing-without" class="custom-control-label">Without</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <nav v-else-if="access_level() == 6 && campaignsList > 3" class="mt-2 collapse show" id="collapseWithListing" aria-labelledby="filter-with-listing">
                <div style="min-height: 100px">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="listing-all"
                                    name="with-active" value="-1" v-model="withActive">
                                <label for="listing-all" class="custom-control-label">All</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="listing-with"
                                    name="with-active" value="1" v-model="withActive">
                                <label for="listing-with" class="custom-control-label">With</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="listing-without"
                                    name="with-active" value="0" v-model="withActive">
                                <label for="listing-without" class="custom-control-label">Without</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            <h4
                v-if="access_level() !== 6"
                id="filter-cc-status"
                style="cursor:pointer;"
                class="a-sidebar-filter-header d-flex justify-content-between align-items-center pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseCCStatus"
                title="Click to show/hide active statuses">
                <div>
                    Active Statuses
                </div>
                <i class="d-block text-muted fas fa-ellipsis-h"></i>
            </h4>

            <nav v-if="access_level() !== 6" class="mt-2 collapse show" id="collapseCCStatus" aria-labelledby="filter-cc-status">
                <div style="min-height: 100px">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item" v-for="(status, index) in filteredStatuses" :key="`cc-status--${index}`">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" name="cc-status" :id="`cc-status--${status.id}`" v-model="ccStatuses.selected" :value="status.id">
                                <label :for="`cc-status--${status.id}`" class="custom-control-label">{{status.label}}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            <h4
                v-if="access_level() !== 6"
                id="filter-sort-by"
                style="cursor:pointer;"
                class="a-sidebar-filter-header d-flex justify-content-between align-items-center pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseSort"
                title="Click to show/hide sort by">
                <div>
                    Sort By
                </div>
                <i class="d-block text-muted fas fa-ellipsis-h"></i>
            </h4>

            <nav v-if="access_level() !== 6" class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                <div style="min-height: 100px">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="sort-all"
                                    name="filterby" value="all" @change="changeFilterOrOrder(-1, 'status')" :checked="$route.query.status == -1">
                                <label for="sort-all" class="custom-control-label">All</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="sort-active"
                                    name="filterby" value="active" @change="changeFilterOrOrder(1, 'status')" :checked="$route.query.status == 1 || $route.query.status === undefined">
                                <label for="sort-active" class="custom-control-label">Active</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="sort-inactive"
                                    name="filterby" value="inactive" @change="changeFilterOrOrder(0, 'status')" :checked="$route.query.status == 0">
                                <label for="sort-inactive" class="custom-control-label">Inactive</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            <h4
                v-if="access_level() !== 6"
                id="filter-order-by"
                style="cursor:pointer;"
                class="a-sidebar-filter-header d-flex justify-content-between align-items-center pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseOrder"
                title="Click to show/hide order by">
                <div>
                    Order By 
                </div>
                <i class="d-block text-muted fas fa-ellipsis-h"></i>
            </h4>

            <nav v-if="access_level() !== 6" class="mt-2 collapse show" id="collapseOrder" aria-labelledby="filter-order-by">
                <div style="min-height: 100px">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="order-numAsc"
                                    name="orderby" value="numDesc" @change="changeFilterOrOrder('numAsc', 'order')" :checked="$route.query.order === 'numAsc'">
                                <label for="order-numAsc" class="custom-control-label">Number of data - Ascending</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="order-numDesc"
                                    name="orderby" value="numDesc" @change="changeFilterOrOrder('numDesc', 'order')" :checked="$route.query.order === 'numDesc' || $route.query.order === undefined">
                                <label for="order-numDesc" class="custom-control-label">Number of data - Descending</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="order-asc"
                                    name="orderby" value="asc" @change="changeFilterOrOrder('asc', 'order')" :checked="$route.query.order === 'asc'">
                                <label for="order-asc" class="custom-control-label">Campaign Name - Ascending</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="order-desc"
                                    name="orderby" value="desc" @change="changeFilterOrOrder('desc', 'order')" :checked="$route.query.order === 'desc'">
                                <label for="order-desc" class="custom-control-label">Campaign Name - Descending</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </template>
        <template v-else>
            <!-- <template v-if="filters.status === 'Cases' && ($route.params.case_id != '0' && typeof $route.params.case_id !== 'undefined')">  -->
            <template v-if="$route.params.status === 'Cases' && ($route.params.case_id != '0' && typeof $route.params.case_id !== 'undefined')">
                <h4 
                    id="filter-seller-flags"
                    style="cursor:pointer;"
                    class="a-sidebar-filter-header d-flex justify-content-between align-items-center pl-4 pr-4 pt-2"
                    data-toggle="collapse"
                    data-target="#collapseSellerFlags"
                    title="Click to show/hide seller flags">
                    <div>
                        {{ $t('Seller Flags') }}
                    </div>
                    <i class="d-block text-muted fas fa-ellipsis-h"></i>
                </h4>

                <nav class="mt-2 collapse show" id="collapseSellerFlags" aria-labelledby="filter-seller-flags">
                    <div style="min-height: 100px">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <!-- <li class="nav-item a-nav-filter-item">
                                <div class="custom-control custom-checkbox">
                                    <input :value="All" class="custom-control-input" type="checkbox" id="diha1" name="seller_flag" v-model="filters.seller_flags" checked>
                                    <label for="diha1" class="custom-control-label">All</label>
                                </div>
                            </li> -->
                            <!-- <li class="nav-item a-nav-filter-item" v-for="(seller_flag,index) in seller_flags" :key="index"> -->
                            <li class="nav-item a-nav-filter-item" v-for="(seller_flag,index) in sellerFlags" :key="index">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" type="checkbox" name="seller_flag" :id="seller_flag" v-model="selected_seller_flags" :value="seller_flag">
                                    <label :for="seller_flag" class="custom-control-label">{{seller_flag}}</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>

                
                <template v-if="counsels.length > 0">
                    <h4 
                        id="filter-counsel"
                        style="cursor:pointer; border-top: 1px solid #eee"
                        class="a-sidebar-filter-header d-flex justify-content-between align-items-center pt-3 pr-4 pl-4 pb-1"
                        data-toggle="collapse"
                        data-target="#collapseCounsel"
                        title="Click to show/hide counsel">
                        <div>
                            Counsel
                        </div>
                        <i class="d-block text-muted fas fa-ellipsis-h"></i>
                    </h4>
                    <nav class="mt-2 collapse show" id="collapseCounsel" aria-labelledby="filter-counsel">
                        <div style="min-height: 100px">
                            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                data-accordion="false">
                                <li class="nav-item a-nav-filter-item" v-for="(counsel,index) in counsels" :key="index">
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" name="counsel" :id="counsel" v-model="selected_counsels" :value="counsel">
                                        <label :for="counsel" class="custom-control-label">{{counsel}}</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </template>

                <h4 
                    id="filter-payment-methods"
                    style="cursor:pointer; border-top: 1px solid #eee"
                    class="a-sidebar-filter-header d-flex justify-content-between align-items-center pt-3 pr-4 pl-4 pb-1"
                    data-toggle="collapse"
                    data-target="#collapsePaymentMethods"
                    title="Click to show/hide payment methods">
                    <div>
                        {{ $t('Payment Methods') }}
                    </div>
                    <i class="d-block text-muted fas fa-ellipsis-h"></i>
                </h4>
                <nav class="mt-2 collapse show" id="collapsePaymentMethods" aria-labelledby="filter-payment-methods">
                    <div style="min-height: 100px">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <!-- <li class="nav-item a-nav-filter-item" v-for="(method,index) in payment_methods" :key="index"> -->
                            <li class="nav-item a-nav-filter-item" v-for="(method,index) in caseSellerAccountsTypes" :key="index">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" type="checkbox" name="method" :id="method.value" v-model="selected_payment_methods" :value="method.label">
                                    <label :for="method.value" class="custom-control-label">{{method.label}}</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
                
            </template>
    
            <template v-else>
                <ul v-if="show_error" class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <li class="nav-item a-nav-filter-item">
                        <div class="custom-control custom-checkbox">
                            <input :checked="show_error" id="show_error" @click="changeShowError" class="custom-control-input" type="checkbox" name="show_error" />
                            <label for="show_error" class="custom-control-label">Show error(s) only</label>
                        </div>
                    </li>
                </ul>
                
                <h4 
                    id="filter-research-status"
                    style="cursor:pointer;"
                    class="a-sidebar-filter-header d-flex justify-content-between align-items-center pl-4 pr-4 pt-2"
                    data-toggle="collapse"
                    data-target="#collapseStatus"
                    title="Click to show/hide research statuses">
                    <div>
                        <img class="a-sidebar-filter-header-icon mr-2" src="/static/svg/Research Status.svg" alt="icon">
                        Data Status
                    </div>
                    <i class="d-block text-muted fas fa-ellipsis-h"></i>
                </h4>

                <!-- <div v-if="subStatusesStore.canSeeSubStatuses" class="pl-3 pt-2 pb-3" style="border-bottom: 1px solid rgb(238, 238, 238);">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" name="show-status" id="show-substatus-checkbox" :value="true" v-model="subStatusesStore.showListingsWithSubstatuses" />
                        <label for="show-substatus-checkbox" class="custom-control-label">Show Listings With Sub-status set</label>
                    </div>
                </div> -->

                <nav class="mt-2 collapse show" id="collapseStatus" aria-labelledby="filter-research-status">
                    <ArLoaderinator :loading="!dem_statuses.length">
                        <div style="min-height: 100px">
                            <!-- <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                data-accordion="false">
                                <li class="nav-item a-nav-filter-item" v-for="(status, index) in dem_statuses" :key="index">
                                    <div class="custom-control custom-radio"  @click="lawfirmChangeStatus(status.label)">
                                        <input class="custom-control-input" type="radio" :id="`status-${status.label != null ? status.label.replace(/\s/g, '-') : ''}`"
                                            name="statuses" :value="status.label" :checked="urldecode($route.params.status) == status.label">
                                        <label :for="`status-${status.label != null ? status.label.replace(/\s/g, '-') : ''}`" class="custom-control-label">{{status.label}} {{ status.val !== "" ? `(${status.val})` : '' }}</label>
                                    </div>
                                </li>
                            </ul> -->
                            <div id="status-accordion" class="btn-group-vertical btn-group-toggle w-100 pt-2 pl-3 pr-3 pb-3" :data-toggle=" !hasUsed_globalSearch ? 'buttons' : ''" >
                                <!-- : refreshCM_sidebar(status) -->
                                <div v-for="(status, index) in dem_statuses" 
                                    :key="index"
                                    class="w-100">
                                    <div class="position-relative w-100">
                                        <label 
                                            v-tooltip="status.info"
                                            @click="lawfirmChangeStatus(status.label)"
                                            class="btn btn-research-status w-100 mb-0"
                                            :class="[
                                                // {'active': urldecode($route.params.status) == status.label},
                                                {'active': hasUsed_globalSearch 
                                                    ? status.val.count >= 1 
                                                    : shouldHighlightStatus(status.label)
                                                },
                                                `cm-filter-${(status.label.includes('KAV') ? 'Accepted' : status.label.includes('HSPRD') ? 'Recycled' : status.label).toLowerCase().replace(/\s/g, '-')}`
                                            ]"
                                            v-restrict="`cm-filter-${(status.label.includes('KAV') ? 'Accepted' : status.label.includes('HSPRD') ? 'Recycled' : status.label).toLowerCase().replace(/\s/g, '-')}`"
                                        >
                                            <input type="radio" v-show="false" name="statuses" :value="status.label" :id="`status-${status.label != null ? (status.label.includes('KAV') ? 'Accepted' : status.label.includes('HSPRD') ? 'Recycled' : status.label).replace(/\s/g, '-') : ''}`" :checked="shouldHighlightStatus(status.label)"> 
                                            <span class="btn-research-status-icon text-uppercase mr-2" :class="[`color-${randomColorNumber()}`]">{{status.label[0]}}</span> {{status.label}} 
                                            {{ status.val.count !== "" ? `[${status.val.count}]` : '' }}
                                        </label>
    
                                        <a :data-toggle="status.label == 'Cases' || status.val.sub_statuses.length ? 'collapse' : ''"
                                            :data-target="status.label == 'Cases' || status.val.sub_statuses.length ? `#cm-filter-${(status.label.includes('KAV') ? 'Accepted' : status.label.includes('HSPRD') ? 'Recycled' : status.label).toLowerCase().replace(/\s/g, '-')}` : ''"
                                            aria-expanded="true" :aria-controls="`cm-filter-${(status.label.includes('KAV') ? 'Accepted' : status.label.includes('HSPRD') ? 'Recycled' : status.label).toLowerCase().replace(/\s/g, '-')}`"
                                            role="button"
                                            v-restrict="`cm-filter-${(status.label.includes('KAV') ? 'Accepted' : status.label.includes('HSPRD') ? 'Recycled' : status.label).toLowerCase().replace(/\s/g, '-')}`">
                                            <i v-if="status.label == 'Cases' || status.val.sub_statuses.length" 
                                                class="d-block text-muted fas fa-caret-down float-right" 
                                                style="font-size: 20px; margin-top: 2px; padding: 8px 20px 8px 10px;" v-tooltip="`Show ${status.label} sub-statuses`"></i>
                                        </a>
                                    </div>
                                    <div 
                                        :id="`cm-filter-${(status.label.includes('KAV') ? 'Accepted' : status.label.includes('HSPRD') ? 'Recycled' : status.label).toLowerCase().replace(/\s/g, '-')}`" 
                                        :aria-labelledby="`cm-filter-${(status.label.includes('KAV') ? 'Accepted' : status.label.includes('HSPRD') ? 'Recycled' : status.label).toLowerCase().replace(/\s/g, '-')}`"
                                        data-parent="#status-accordion"
                                        class="collapse w-100 cm-filter-cases" 
                                        :class="[{'show': hasUsed_globalSearch 
                                                    ? status.val.count >= 1 
                                                    : shouldHighlightStatus(status.label) }]"
                                        v-restrict="`cm-filter-${(status.label.includes('KAV') ? 'Accepted' : status.label.includes('HSPRD') ? 'Recycled' : status.label).toLowerCase().replace(/\s/g, '-')}`"
                                    >
                                        <div class="btn-group-vertical btn-group-toggle w-100 pt-1 pl-3 pr-0 pb-3" v-if="status.label == 'Cases' || (status.label != 'Cases' && status.val.sub_statuses.length)">
                                            <!-- v-for="(tag, tagIndex) in case_status_options" -->
                                            <label
                                                v-for="(tag, tagIndex) in (status.label == 'Cases' ? caseStatusOptions : status.val.sub_statuses)"
                                                class="btn btn-research-status sub-status"
                                                style="padding-left: 30px;"
                                                @click="status.label == 'Cases' ? updateCaseStatus(tag.value) : updateSubStatus(status.label, tag.id ?? `${0}_${status.label?.replaceAll(' ', '_')}`)"
                                                :class="[{'active': (status.label == 'Cases' ? $route.query.case_status != null && $route.query.case_status == tag.value : $route.query.substatus_id == (tag.id ?? `${0}_${status.label?.replaceAll(' ', '_')}` )) }]"
                                                :key="index + tagIndex"
                                            >
                                                <input v-show="false" type="radio" :name="`${status.label}--sub_statuses`" :value="status.label == 'Cases' ? tag.value : tag.id" :id="`${status.label}--sub-status-${tag.id ?? (0 + ' ' + status.label?.replaceAll(' ', '_'))}}`" :checked="status.label == 'Cases' ? $route.query.case_status == tag.value : $route.query.substatus_id == tag.id">{{tag.label}} {{ tag.count !== "" && typeof tag.count != "undefined" ? `[${tag.count}]` : '' }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div 
                                    id="caseTags" 
                                    class="collapse w-100 cm-filter-cases" 
                                    :class="{'show': $route.params.status == 'Cases' && $route.params.case_id == 0 }"
                                    v-restrict="'cm-filter-cases'"
                                >
                                    <div class="btn-group-vertical btn-group-toggle w-100 pt-1 pl-3 pr-0 pb-3" data-toggle="buttons">
                                        < !-- v-for="(tag, tagIndex) in case_status_options" -- >
                                        <label
                                            class="btn btn-research-status"
                                            @click="updateCaseStatus(tag.value)"
                                            :class="[{'active': $route.query.case_status != null && $route.query.case_status == tag.value}]"
                                            v-for="(tag, tagIndex) in caseStatusOptions"
                                            :key="tagIndex"
                                        >
                                            <input type="radio" name="case_statuses" :value="tag.value" :id="`case-status-${tag.label}`" :checked="$route.query.case_status == tag.value">{{tag.label}} {{ tag.count !== "" && typeof tag.count != "undefined" ? `[${tag.count}]` : '' }}
                                        </label>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </ArLoaderinator>
                </nav>

                <!-- <template v-if="filters.status === 'Cases' && ($route.params.case_id == 'undefined' || $route.params.case_id == 0) && access_level() == 7"> -->
                <template v-if="filters.status === 'Cases' && ($route.params.case_id == 'undefined' || $route.params.case_id == 0)">
                    <div 
                        class="cases-button animated bounceInRight cm-link-create-case"
                        v-restrict="'cm-link-create-case'"
                    >
                        <ar-button :is_fab="true" data-toggle="modal" data-target="#new-case-modal"><i class="fas fa-plus"></i> Create a New Case</ar-button>
                    </div>

                </template>
            </template>

            <template v-if="!(filters.status === 'Cases' && ($route.params.case_id == 'undefined' || $route.params.case_id == 0))">
                <h4 
                    class="a-sidebar-filter-header d-flex justify-content-between align-items-center pt-3 pr-4 pl-4 pb-1"
                    style="cursor:pointer; border-top: 1px solid #eee"
                    id="filter-platform"
                    title="Click to show/hide platforms"
                >
                    <div data-toggle="collapse" data-target="#collapsePlatform">
                        <img class="a-sidebar-filter-header-icon mr-2" src="/static/svg/Platforms.svg" alt="icon">
                        <span> {{ $t('Platforms') }} </span>
                    </div>
                    <div
                        class="cm-link-platforms-breakdown'"
                        v-restrict="'cm-link-platforms-breakdown'"
                    >
                    <!-- v-if="($route.params.status != 'Cases' || $route.params.case_id != '0') && $route.query.substatus_id.toString().includes('0_')" -->
                        <a
                            v-if="($route.params.status != 'Cases' || $route.params.case_id != '0')  && 
                                   ($route?.query?.substatus_id?.toString().includes('0_') || $route?.query?.substatus_id?.toString().includes('-1'))"
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#platform-data-breakdown-more-filter"
                        >
                            <img src="/static/svg/Action_disable.svg"
                                title="Customize Platform Data Breakdown"
                                style="height: 20px; margin-bottom: 5px;"
                            >
                        </a>
                        <a
                            v-if="$route.params.status != 'Cases' || $route.params.case_id != '0'"
                            href="javascript:void(0);"
                            class="btn d-inline-block btn-primary mr-2"
                            style="background-color: #FFFFFF; border: none; margin-left: 90px;"
                            data-toggle="modal"
                            data-target="#platform-data-breakdown-modal"
                            v-tooltip   ="`Show Data Breakdown by Platforms`"
                        >
                            <i style="font-size: 20px; color: #03b2c8" class="fas fa-folder-open"></i>
                        </a>
                    </div>
                    <i class="d-block text-muted fas fa-ellipsis-h" data-toggle="collapse" data-target="#collapsePlatform"></i>
                </h4>

                <nav class="mt-2 collapse show" id="collapsePlatform" aria-labelledby="filter-research-platform">
                    <div style="min-height: 100px">
                        <ar-textfield
                            icon="fas fa-search"
                            :class="`ml-3 mr-3 mb-0`"
                            :placeholder="$t('Search Platforms')"
                            v-model="filter_platform"
                        />
                        <!-- <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-item a-nav-filter-item" v-for="(platform,index) in lf_platforms" :key="index">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" type="checkbox" name="platform" :id="`platform-${platform.value}`" v-model="selected_lf_platforms" :value="platform.value">
                                    <label :for="`platform-${platform.value}`" class="custom-control-label">{{platform.label}} {{platform.value}} type here</label>
                                </div>
                            </li>
                        </ul> -->
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-item a-nav-filter-item" v-for="(value, key, index) in lf_platforms_vuex2" :key="index">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" type="checkbox" name="platform" :id="`platform-${value[0]?.platform_id}`" v-model="selected_lf_platforms" :value="value[0]?.platform_id">
                                    <label :for="`platform-${value[0]?.platform_id}`" class="custom-control-label">
                                        {{key}}  [{{lf_platforms_vuex2[key]?.length}}]
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </template>
            <template v-if="!(filters.status === 'Cases' && ($route.params.case_id == 'undefined' || $route.params.case_id == 0)) && !($route.params.status === 'Cases')">
                <h4 
                    class="a-sidebar-filter-header d-flex justify-content-between align-items-center pt-3 pr-4 pl-4 pb-1"
                    style="cursor:pointer; border-top: 1px solid #eee"
                    id="filter-payment-type"
                    title="Click to show/hide payment"
                >
                    <div data-toggle="collapse" data-target="#collapsePaymentType">
                        <img class="a-sidebar-filter-header-icon mr-2" src="/static/svg/payment-type.svg" alt="icon">
                        <span> Payment Accounts </span>
                    </div>
                    <div
                        class="cm-link-payment-accounts-breakdown"
                        v-restrict="'cm-link-payment-accounts-breakdown'"
                    >
                        <a
                            v-if="$route.params.status != 'Cases'"
                            href="javascript:void(0);"
                            class="btn d-inline-block btn-primary mr-2"
                            style="background-color: #FFFFFF; border: none; margin-left: 50px;"
                            data-toggle="modal"
                            data-target="#available-accounts-modal"
                            v-tooltip   ="`Show Available Payment Accounts`"
                        >
                            <i style="font-size: 20px; color: #03b2c8" class="fas fa-folder-open"></i>
                        </a>
                    </div>
                    <i data-toggle="collapse" data-target="#collapsePaymentType" class="d-block text-muted fas fa-ellipsis-h"></i>
                </h4>

                <nav class="mt-2 collapse show" id="collapsePaymentType" aria-labelledby="filter-research-payment-types">
                    <div style="min-height: 100px">
                        <ar-textfield
                            icon="fas fa-search"
                            :class="`ml-3 mr-3 mb-0`"
                            placeholder="Search payment types"
                            v-model="filter_payment_type"
                        />
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-item a-nav-filter-item" v-for="(value, key, index) in access_level() == 6  ? filtered_case_seller_account : filteredCaseSellerAccount" :key="index">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" type="checkbox" name="paymentType" :id="`payment-type-${key}`" v-model="selected_payment_types" :value="key">
                                    <label :for="`payment-type-${key}`" class="custom-control-label">
                                        {{key}} [{{access_level() == 6  ? filtered_case_seller_account[key]?.length : filteredCaseSellerAccount[key]?.length}}]
                                    </label>
                                    <!-- [{{case_seller_account[key]?.length}}] -->
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </template>
            <template v-if="filters.status != 'Cases' || (filters.status == 'Cases' && $route.params.case_id == 'undefined' || $route.params.case_id != 0)">
                    <div class="apply-filter-button">
                        <ar-button :is_fab="true" @click="$route.params.status === 'Cases' && ($route.params.case_id != '0' && typeof $route.params.case_id !== 'undefined') ? applyCaseFilters() : applyPlatformFilters()" class="d-block w-100 mr-2" style="font-size: 12px; font-weight: 300" :title="sideStuff.title">{{ sideStuff.label }}</ar-button>
                    </div>
                </template>
        </template>
    </div> 
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import axios from '@/axiosMime'
import TerritoryVue from '@/components/pages/admin/Territory.vue'
import { useFeatureAccessStore } from '../../../store/pinia/feature-access';
import { useSubStatusesStore } from '../../../store/pinia/substatuses';
import { useCommonStore } from '../../../store/pinia/common';
import qs from 'qs';

export default {
  name: 'cm-drawer',
  components: {
    ArButton: _ => import('@/components/layouts/Button'),
    ArTextfield: _ => import('@/components/layouts/Textfield'),
    ArLoaderinator: _ => import('@/components/layouts/loading/Loaderinator'),
  },
  beforeCreate() {
    if(this.$route.params.status != null && typeof this.$route.params.status == 'undefined'){
        this.status = this.$route.params.status
        this.filters.status = this.$route.params.status
    }
  },
  async created() {
    this.setFilters(this.filters)
    if (this.$route.params.campaign_id != null) {
        this.getCounsels()
        // this.getLfPlatforms()
        this.getPaymentTypes()
        this.getCaseSellerAccounts()
        this.getPlatformDataBreakdown()
    }
  },
  async mounted() {
    if (this.$route.name == 'lawfirmcounterfeitchecker') {
        await this.getQflags()
        this.ccStatuses.selected = this.$route.query.statuses === undefined ? [] : this.$route.query.statuses
        this.withActive = this.$route.query.withActive === undefined ? -1 : this.$route.query.withActive
    }

    this.status = this.$route.params.status
    this.filters.status = this.$route.params.status
    if(this.$route.params.campaign_id != null) {
        // this.getCaseSellerAccounts()
        this.getPlatformDataBreakdown()
    }
    this.user = this.getCurrentUser()
    this.getSellerFlags()

    this.initializeStatuses(true)
  },
  data() {
    return {
      filters: {
          status : typeof this.$route.params.status === 'undefined' ? '' : this.$route.params.status,
          selected_seller_flags : [],
          selected_counsels: [],
          selected_payment_methods: [],
          selected_lf_platforms : [],
          selected_payment_types: [],
          platforms: []
      },
      isCampaignChanged: false,
      seller_flags : [],
      selected_lf_platforms: [],
      selected_seller_flags : [],
      counsels: [],
      selected_counsels: [],
      selected_payment_methods: [],
      selected_payment_method: '0',
      selected_payment_types: [],
      payment_methods: [],
      dem_statuses: [],
      lf_platforms: [],
      filter_platform: "",
      filter_payment_type: "",
      shown_data: [],
      case_status_options: [],
      cases_statuses: [],
      user: null,
      currentUser: null,
      currentCampaign: [],
      ccStatuses: {
          data: [],
          selected: []
      },
      withActive: -1,
      case_seller_account: [],
      show_available_payment_accounts: false,
      selected_payment_accounts: false,
      show_platform_data_breakdown : true
    }
  },
  computed: {
    ...mapState(['show_error', 'feature_access', 'hasUploadedCaseFile', 'hasDeletedCaseFile', 'hasUsed_globalSearch', 'globalSearch_count','globalSearch_listings','case_seller_accounts_vuex', 'lf_platforms_vuex2']),
    ...mapGetters(['getCcCampaigns']),
    subStatusesStore() {
        return useSubStatusesStore()
    },
    displayedSellerNames() {
        let result = [];
        for (var platform of Object.keys(this.lf_platforms_vuex2))
        {
            for (var listing of this.lf_platforms_vuex2[platform])
            {
                result.push(listing.seller_name);
            }
        }

        return result;
    },
    filtered_case_seller_account() {
        let case_seller_account = this.case_seller_account
        if(!this.hasUsed_globalSearch){
            let arr_payment = {}

            let hasPaypal = false
            let hasPaypal_noAccount = false
            let hasAlibaba = false
            let hasAlipay = false
            let hasDHpay = false
            let hasDHGate = false

            let previous_Alipay = {}
            let previous_Alibaba_noAccount = {}
            let previous_Paypal = {}
            let previous_Paypal_noAccount = {}
            let previous_DHpay = {}
            let previous_DHGate_noAccount = {}

            let alipay = 'Alipay'
            let paypal = 'Paypal'
            let dhpay = 'DHPay'
            
            // checking first

            for(var j in case_seller_account){
                if(j.toString().toLowerCase() == 'paypal'){
                    hasPaypal = true
                    previous_Paypal = case_seller_account[j]
                }
                if(j.toString().toLowerCase() == 'alipay'){
                    hasAlipay = true
                    previous_Alipay = case_seller_account[j]
                }
                if(j.toString().toLowerCase() == 'dhpay'){
                    hasDHpay = true
                    previous_DHpay = case_seller_account[j]
                }
            }

            for(var i in case_seller_account){

                if (!i.toString().toLowerCase().includes('no account')){
                    if(!(i.toString().toLowerCase().includes('alipay') || i.toString().toLowerCase().includes('dhpay') || i.toString().toLowerCase().includes('paypal'))){
                        arr_payment[this.remove_noAccountLabel(i)] = case_seller_account[i]
                    }
                    
                }else {
                    
                    // for payment_type ==  "No Account"
                    if(i.toString().toLowerCase() == 'no account'){
                        arr_payment[i] = case_seller_account[i]
                    }
                    
                    if(i.toString().toLowerCase().includes('paypal') && i.toString().toLowerCase().includes('no account')){
                        hasPaypal_noAccount = true
                        previous_Paypal_noAccount = case_seller_account[i]
                        if(hasPaypal){
                            arr_payment[paypal] = [...previous_Paypal, ...previous_Paypal_noAccount]
                        }else {
                            arr_payment[paypal] = case_seller_account[i]
                        }
                    }
                    if(i.toString().toLowerCase().includes('alibaba') && i.toString().toLowerCase().includes('no account')) {
                        previous_Alibaba_noAccount = case_seller_account[i]
                        hasAlibaba = true
                        if(hasAlipay){
                            arr_payment[alipay] = [...previous_Alipay, ...previous_Alibaba_noAccount]
                        }else {
                            arr_payment[alipay] = previous_Alibaba_noAccount
                        }
                    }
                    if(i.toString().toLowerCase().includes('dhgate') && i.toString().toLowerCase().includes('no account')) {
                        previous_DHGate_noAccount = case_seller_account[i]
                        hasDHGate = true
                        if(hasDHpay){
                            arr_payment[dhpay] = [...previous_DHpay, ...previous_DHGate_noAccount]
                        }else {
                            arr_payment[dhpay] = previous_DHGate_noAccount
                        }
                    }             
                }
            }

            for (var paymentType of Object.keys(arr_payment))
            {
                let filteredAccounts = [];
                for (var sellerAccount of arr_payment[paymentType])
                {
                    if (this.displayedSellerNames.includes(sellerAccount.seller_name))
                    {
                        filteredAccounts.push(sellerAccount);
                    }
                }
                arr_payment[paymentType] = filteredAccounts;
            }
            // console.log(case_seller_account)
            // console.log(arr_payment)
            // return case_seller_account
            return arr_payment
        }
        return case_seller_account
    },
    computedLFPlatforms() {
        return this.lf_platforms_vuex2
    },
    filteredCaseSellerAccount() {
        const lfPlatforms = this.computedLFPlatforms;
        const caseSellerAccount = this.case_seller_account;
        
        if (!lfPlatforms || !caseSellerAccount) return [];

        const lfSellerNames = Object.values(lfPlatforms).flatMap(platform => platform.map(item => item.seller_name));

        return Object.fromEntries(
        Object.entries(caseSellerAccount).map(([platform, sellers]) => [
            platform,
            sellers.filter(seller => lfSellerNames.includes(seller.seller_name))
        ])
        );
    },
    campaignsList() {
        let campaigns = this.getCcCampaigns.length
        return campaigns
    },
    sideStuff() {
        return this.$route.params.status === 'Cases' && (this.$route.params.case_id != '0' && typeof this.$route.params.case_id !== 'undefined') ? { title: "Apply specified platforms filter on search", label: "Apply Filters" }  : { title: "Apply specified filters", label: "Apply Filter" }
    },
    isGodzillaUser() {
        return this.user ? this.user.cxids.some(c => c.id === 723) : {}
    },
    isClientAllowedForHasPotential() {
        return [1044, 1074].includes(this.user.id)
    },
    isCampaignGodzilla() {
        return this.$route.params.campaign_id == 723
    },
    parent() {
        let p = this.$parent
        let count = 0

        while (p.$vnode.componentOptions.tag != 'ar-master' && count < 6) {
            p = p['$parent']
            count++
        }

        return p
    },
    ccQflags() {
        return this.ccStatuses.data
    },
    filteredStatuses() {
        let statuses = []
        let filtered = []
        
        this.getCcCampaigns.map(c => c.status_ids).forEach(c => statuses = [...new Set([...statuses, ...c])])

        statuses.forEach(s => {
            let found = this.ccQflags.find(d => d.id == s)

            if (found) {
                /**
                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                 * Deliverable Number: 454
                 */
                // if (found.label == 'Recheck') found = {...found, label: 'For Rework'}
                if (found.label == 'Researched') found = {...found, label: 'For Quality Checking'}
                if (found.label == 'Qualified') found = {...found, label: 'For Review'}

                filtered.push(found)
            }
        })

        return filtered.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
    },
    caseStatusOptions() {
        let caseStatusOptions = this.case_status_options;

        /**
         * Remove certain status from `caseStatusOptions` with the given parameter.
         * 
         * @param { string } statusToRemove Status to remove from `caseStatusOptions`.
         */
        const filterStatus = (statusToRemove = '') => {
            const filteredStatus = caseStatusOptions.filter(status => status.label.toLowerCase() !== statusToRemove.toLowerCase());

            return filteredStatus;
        }

        if (this.isFeatureRestricted('cm-filter-joinder')) {
            caseStatusOptions = filterStatus('joinder');
        }

        if (this.isFeatureRestricted('cm-filter-excessive-freeze')) {
            caseStatusOptions = filterStatus('excessive freeze');
        }

        if (this.isFeatureRestricted('cm-filter-electronic-service')) {
            caseStatusOptions = filterStatus('electronic service');
        }

        return caseStatusOptions;
    },
    restrictedFeatures() {
        const store = useFeatureAccessStore();
        return store.restrictedFeatures;
    },
    sellerFlags() {
        return this.seller_flags.map(flag => this.$t(flag));
    },
    caseSellerAccountsTypes() {
        return this.payment_methods.filter(method => useCommonStore().caseSellerAccountsTypes.includes(method.label.toLowerCase()));
    }
  },
  methods: {
    ...mapActions(['setFilters', 'updateShown', 'changeShowError', 'checkFeatureAccess', 'cancelRequests', 'refreshCancelToken', 'updateLawfirmCoverPage']),
    shouldHighlightStatus(label) {
        const status = this.urldecode(this.$route.params.status)
        if (this.access_level() != 7)
            return status == label
        
        return ['Accepted', 'Recycled'].includes(status) 
            ? (label == 'Accepted (KAV)' && status == 'Accepted') || (label == 'Accepted (HSPRD)' && status == 'Recycled')
            : status == label
    },
    getCurrentUser() {
        return JSON.parse(localStorage.getItem('lawfirm'))
    },
    applyCaseFilters() {
      this.filters = {
          selected_lf_platforms: this.selected_lf_platforms,
          selected_counsels: this.selected_counsels,
          selected_payment_methods: this.selected_payment_methods,
          selected_seller_flags: this.selected_seller_flags
      }
    },
    changeFilterOrOrder(val, type) {
        let query = {...this.$route.query};
        query[type] = val;
        this.$router.push({name: this.$route.name, query: query});
    },
    async getQflags() {
        let headers = JSON.parse(JSON.parse(localStorage.getItem('lawfirm')).headers)
        if (headers != null) {
            await axios.get(`${process.env.VUE_APP_URL}/qflag?page_size=10000`, 
                headers
                ).then(
                    async ({data}) => {
                    this.ccStatuses.data = data.data
                }
            )
            .catch(err => {
                console.log(err)
                this.parent.showSnackbar('Something went wrong while getting Counterfeit checker statuses. Plase contact system admin.', false, 'invalid', 'fas fa-times-circle')
            })
        }
    },
    async getSellerFlags() {
        let headers = JSON.parse(localStorage.getItem('headers'))
        if (headers != null) {
            axios.get(`${process.env.VUE_APP_URL}/case_flags`, 
                headers
                ).then(
                    async response => {
                    this.seller_flags = response.data.data.filter(flag => flag.status == 1).map(flag => flag.name)
                    let initial = JSON.stringify(this.seller_flags)
                    this.seller_flags = this.seller_flags.filter(s => !s.toLowerCase().includes("default"))
    
                    if (await this.checkFeatureAccess([4]))
                        this.seller_flags = JSON.parse(initial)
                }
            )
            .catch(err => {
                console.log(err)
                this.parent.showSnackbar('Something went wrong while getting Seller Flag. Plase contact system admin.', false, 'invalid', 'fas fa-times-circle')
            })
        }

        /* const headers = JSON.parse(localStorage.getItem('headers'));

        if (headers === null) {
            return;
        }

        const { data, status } = await axios.get(`${process.env.VUE_APP_URL}/case_flags`, headers);

        if (status !== 200) {
            this.parent.showSnackbar('Something went wrong while getting Seller Flags. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle');
            return;
        }

        const sellerFlags = data.data.map(item => item.name);
        this.seller_flags = sellerFlags;

        if (!this.isFeatureRestricted('cm-text-defaulted-figures')) {
            this.seller_flags = sellerFlags;
            return;
        }

        this.seller_flags = sellerFlags.filter(sellerFlag => !sellerFlag.toLowerCase().includes('default'));
        return; */
    },
    // getLfPlatforms(){
    //   let header = JSON.parse(localStorage.getItem('headers'))
    //   header.headers["Qflag-Id"] = this.parent.getQFlagId(this.$route.params.status)
    //   header.headers["Case-id"] = this.$route.params.case_id
    //   header.headers.Sort = "priority,DESC,name,ASC"

    //   if(this.$route.path.includes('campaign') && this.$route.params.campaign_id != null){
    //       axios.get(`${process.env.VUE_APP_URL}/platforms/in/campaign/${this.$route.params.campaign_id}?page_size=1000`, 
    //                               header
    //                               ).then(
    //                                   response => {
    //                                   this.lf_platforms = []
    //                                   for(let x of response.data.data){
    //                                       this.lf_platforms.push({
    //                                           value : x.id,
    //                                           label : x.name
    //                                       })
    //                                   }
    //                               }
    //                           )
    //   }
    // },
    async getPlatformDataBreakdown() {
        if(!this.hasUsed_globalSearch){
            // this.show_platform_data_breakdown = false
            const headers = this.$session.get("lawfirm_headers")
            
            if (this.subStatusesStore.canSeeSubStatuses && this.$route.query.substatus_id && this.$route.query.substatus_id != -1)
                headers.headers["Substatus-id"] = this.$route.query.substatus_id?.toString().includes('0_') ? -1 : this.$route.query.substatus_id
            
            await this.$http.get(`defendants/breakdown/${this.$route.params.status}/in/campaign/${this.$route.params.campaign_id}/case/${this.$route.params.case_id}/by/platforms`, headers)
            .then(response => {
                // this.show_platform_data_breakdown = true
                this.lf_platforms = {}
                for (let x of response.data.data) {
                    if(typeof this.lf_platforms[x.platform] == 'undefined') this.lf_platforms[x.platform] = []
                    this.lf_platforms[x.platform].push({
                        platform_id: x.platform_id,
                        platform: x.platform,
                        seller_name: x.seller_name,
                        listing_id: x.listing_id
                    })
                }
                this.$store.dispatch('getPlatformBreakdown2', this.lf_platforms)
                setTimeout(() => {
                    this.filterPlatformDataBreakdown()
                }, 500)
            })
        }else {
            let globalListings = this.globalSearch_listings
            globalListings = globalListings.map(listing => {
                return {
                    value: listing.platform_id,
                    label: listing.platform
                }
            }).sort((a, b) => {
                return ((a || {}).label || "").localeCompare((b || {}).label || "")
            })
            this.lf_platforms = {}
            for (let i of globalListings){
                if(typeof this.lf_platforms[i.label] == 'undefined') this.lf_platforms[i.label] = []

                this.lf_platforms[i.label].push({
                    value: i.value,
                    label: i.label
                })
            }
            this.$store.dispatch('getPlatformBreakdown2', this.lf_platforms)
        }
    },
    getPaymentTypes(){
        const headers = this.$session.get("lawfirm_headers")

        if (this.subStatusesStore.canSeeSubStatuses && this.$route.query.substatus_id && this.$route.query.substatus_id != -1)
                headers.headers["Substatus-id"] = this.$route.query.substatus_id?.toString().includes('0_') ? -1 : this.$route.query.substatus_id

        axios.get(`${process.env.VUE_APP_URL}/payment_types/in/campaign/${this.$route.params.campaign_id}/post-filed?page_size=10000`, headers)
            .then(response => {
                this.payment_methods = []
                for(let x of response.data.data){
                    this.payment_methods.push({
                        value: x.id,
                        label : x.payment_name    
                    })
                }
            }
        )
    },
    async getCaseSellerAccounts(){
        // console.log('triggered: cm -> getCaseSellerAccounts')
        if(!this.hasUsed_globalSearch){
            this.show_available_payment_accounts = false
            let payload = {
                status: this.$route.params.status,
                campaign_id: this.$route.params.campaign_id,
                selected_payment_method: this.selected_payment_method,
                substatus_id: this.$route.query?.substatus_id == -1 ? undefined : this.$route.query?.substatus_id?.toString().includes('0_') ? -1 : this.$route.query?.substatus_id
            }
            await this.$store.dispatch('getCaseSellerAccount',payload)

            // console.log('cm -> case_seller_accounts_vuex: ',this.case_seller_accounts_vuex.data)
            if(this.case_seller_accounts_vuex.data !== undefined || this.case_seller_accounts_vuex.data !== null) {
                this.show_available_payment_accounts = true
                this.selected_payment_method = '0'
                this.case_seller_account = {}

                for(let x of this.case_seller_accounts_vuex.data){
                    if(x.payment_type == null){
                        x.payment_type = 'Unknown'
                    }
                    if(typeof this.case_seller_account[x.payment_type] == 'undefined'){
                        this.case_seller_account[x.payment_type] = []
                    }
                    this.case_seller_account[x.payment_type].push(x)
                    
                }
                // console.log(this.case_seller_account)
                
            }

            // axios.get(`${process.env.VUE_APP_URL}/case_seller_accounts/${this.$route.params.status}/in/campaign/${this.$route.params.campaign_id}/with/${this.selected_payment_method}?page_size=1000`,
            // JSON.parse(localStorage.getItem('headers'))).then(
            //     response => {
            //         this.show_available_payment_accounts = true
            //         this.selected_payment_method = '0'
            //         this.case_seller_account = {}
            //             for(let x of response.data.data){
            //                 if(x.payment_type == null){
            //                     x.payment_type = 'Unknown'
            //                 }
            //                 if(typeof this.case_seller_account[x.payment_type] == 'undefined'){
            //                     this.case_seller_account[x.payment_type] = []
            //                 }
            //                 this.case_seller_account[x.payment_type].push(x)
                            
            //             }
            //             // console.log(this.case_seller_account)
            //         }
            //     )
        }else {
            let globalListings = this.globalSearch_listings
            globalListings = globalListings.map(listing => {
                if(listing.qflag_id == 34) {
                    return {
                        case_seller_accounts: listing.case_seller_accounts,
                        seller_accounts: listing.seller[0].accounts,
                        seller_name: listing.seller[0].name,
                    }
                } else {
                    return {
                        seller_accounts: listing.seller[0].accounts,
                        seller_name: listing.seller[0].name,
                    }
                }
            })
            this.case_seller_account = {}
            if(this.access_level() != 6){
                for (let i of globalListings){
                    for(let j=0; j<i.seller_accounts.length; j++){
                        let name_email = i.seller_accounts[j].name_email
                        // console.log(name_email)
                        // console.log(i.seller_accounts[j])
                        if(i.seller_accounts[j].type == null){
                            i.seller_accounts[j].type = 'Unknown'
                        }
                        if(typeof this.case_seller_account[i.seller_accounts[j].type] == 'undefined'){
                            this.case_seller_account[i.seller_accounts[j].type] = []
                        }
                        this.case_seller_account[i.seller_accounts[j].type].push({type: i.seller_accounts[j].type,seller_name: i.seller_name,name_email: name_email})
                        
                    }
                }
            }else {
                for (let i of globalListings){
                    for(let j=0; j<i.seller_accounts.length; j++){
                        let name_email = i.seller_accounts[j].name_email
                        // console.log(name_email)
                        // console.log(i.seller_accounts[j])
                        if(i.seller_accounts[j].client_payment_type == null){
                            i.seller_accounts[j].client_payment_type = 'Unknown'
                        }
                        if(typeof this.case_seller_account[i.seller_accounts[j].client_payment_type] == 'undefined'){
                            this.case_seller_account[i.seller_accounts[j].client_payment_type] = []
                        }
                        this.case_seller_account[i.seller_accounts[j].client_payment_type].push({type: i.seller_accounts[j].client_payment_type, seller_name: i.seller_name,name_email: name_email})
                        
                    }
                }
            }
        }
    },
    getCounsels(){
        let header = JSON.parse(localStorage.getItem('headers'))
        // header.headers.where = `{"case_id": { "listings" : { "campaign_id" : ${this.$route.params.campaign_id} } }}`
        header.headers.where = `{"case_id": ${this.$route.params.case_id} }`
        axios.get(`${process.env.VUE_APP_URL}/case_listings/multi`, 
                    header
                    ).then(
                        response => {
                        this.counsels = []
                        for(let x of response.data.data){
                            if(!this.counsels.includes(x.counsel) && x.counsel != null && x.counsel != ""){
                                this.counsels.push(x.counsel)
                            }
                        }
                    }
                )
    },
    uncheckPlatforms() {
        let platforms = document.querySelectorAll('input[name=platforms]')
        this.selected_checkbox_count = 0

        for (var i = 0; i < platforms.length; i++) {
            if (platforms[i].value == 0) {
                platforms[i].checked = true 
                continue
            }

            platforms[i].checked = false
        }
    },
    uncheckPaymentTypes() {
        // let paymentTypes = document.querySelectorAll('input[name=paymentType]')
        // this.selected_checkbox_count = 0

        // for (var i = 0; i < paymentTypes.length; i++) {
        //     paymentTypes[i].checked = false
        //     console.log(paymentTypes[i])
        // }

        this.selected_payment_types = []
    },
    disablePlatforms(status = true) {
        let platforms = document.querySelectorAll('input[name=platforms]')

        for (var i = 0; i < platforms.length; i++) {
            if (platforms[i].value == 0) continue

            if (status) platforms[i].attr("disabled", "disabled")
            else platforms[i].removeAttr("disabled")
        }
    },
    async initializeStatuses(backdrop = false) {
        if (this.$route.params.campaign_id != null) {
            this.currentUser = this.getCurrentUser()

            if (backdrop) {
                // this.parent.showBackdrop()
            }

            await this.getCaseStatuses();
            let campaign = null

            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)

            headers.headers.Where = JSON.stringify({id: this.$route.params.campaign_id})

            await this.$http.get(`lawfirm_campaigns?page_size=1000`, headers)
            .then(async response => {
                campaign = response.data.data[0]
                this.currentCampaign = response.data.data[0]

                this.updateLawfirmCoverPage(this.currentCampaign.with_cover_page)

                if (this.subStatusesStore.canSeeSubStatuses) {
                    const { data } = await this.$http.get(`substatuses/${this.$route.params.campaign_id}/listingCountPerSubstatus?page_size=10000000`, this.$session.get('lawfirm_headers'))
                    this.subStatusesStore.subStatuses = data.data
                }
                
                // Added Count of Recycled to Accepted
                const accepted_count = campaign.total_items_by_status.accepted + campaign.total_items_by_status.recycled
                const specificCampaignsToMergeAcceptedAndRecycledCount = [
                    563, // Nestle 
                    849, // Camelbak
                    1074, // Bushnell
                    1073, // Blackhawk
                    1081, // Boresnake
                    1163, // CQC
                    1164, // SERPA
                    131, // GIRO
                    1168 // GIRO UK
                ]

                let obj = { 
                    "Has Potential"    : campaign.total_items_by_status["has potential"],
                    "For Review"       : campaign.total_items_by_status.qualified,
                    "Monitoring"       : campaign.total_items_by_status.monitoring,
                    "Initial Review Completed" : campaign.total_items_by_status["initial review completed"],
                    // "Accepted"         : this.access_level() == 6 ? accepted_count : campaign.total_items_by_status.accepted, // previous version of below
                    "Accepted"         : (this.access_level() == 6 && specificCampaignsToMergeAcceptedAndRecycledCount.includes(this.$route.params.campaign_id)) ? accepted_count : campaign.total_items_by_status.accepted,
                    "Submitted"        : campaign.total_items_by_status.submitted,
                    "Rejected"         : campaign.total_items_by_status.rejected,
                    "For Internal Review" : campaign.total_items_by_status["for internal review"],
                    "Recycled"         : campaign.total_items_by_status.recycled,
                    "Filed"            : campaign.total_items_by_status.filed, // added for new status "Filed"
                    "Cases"            : campaign.total_items_by_status.cases,
                    // "Auto Qualified"   : campaign.total_items_by_status.hacked
                }

                // obj = Object.entries(obj).reduce((a, v) => ({ ...a, [v[0]]: { count: v[1], sub_statuses: this.subStatusesStore.subStatuses.total_item_by_substatus[v[0] == 'For Review' ? 'Qualified' : v[0]] ?? [] } }), {})
                obj = Object.entries(obj).reduce((a, v) => {
                    let sorted = this.subStatusesStore.subStatuses?.total_item_by_substatus?.[v[0] == 'For Review' ? 'Qualified' : v[0]] ?? []
                    sorted.sort((a, b) => a.id ?? 0 - b.id ?? 0)
                    return ({ ...a, [v[0]]: { count: v[1], sub_statuses: sorted.map(s => {
                        const count = sorted.reduce((difference, item) => difference - item.count, v[1])
                        return s.id == null ? {...s, count} : {...s}
                    }) } }) 
                }, {})
                
                /* if (this.access_level() == 6 && !this.isClientAllowedForHasPotential)
                    delete obj["Has Potential"]

                if (!((this.access_level() == 7 || this.isGodzillaUser) && this.isCampaignGodzilla))
                    delete obj["Initial Review Completed"]

                if (this.access_level() != 7) {
                    delete obj["For Internal Review"]
                    delete obj["Recycled"]
                }

                if (!(this.access_level() == 7 || this.hasCaseStatusAccess()))
                    delete obj["Cases"] */

                if (this.isFeatureRestricted('cm-filter-has-potential') || this.access_level() == 6) {
                    delete obj['Has Potential'];
                }

                if (this.isFeatureRestricted('cm-filter-initial-review-completed')) {
                    delete obj["Has Potential"];
                }

                if (this.isFeatureRestricted('cm-filter-for-internal-review')) {
                    delete obj['For Internal Review'];
                }

                if (this.isFeatureRestricted('cm-filter-cases')) {
                    delete obj.Cases;
                }

                // change the filter name if the user is an external user, mostly super admin (7)
                if ([7].includes(this.access_level())) {
                    let temp = Object.entries(obj)
                    const acceptedIndex = temp.findIndex(([k, v]) => k == 'Accepted')
                    const recycledIndex = temp.findIndex(([k, v]) => k == 'Recycled')
                    temp[acceptedIndex] = ['Accepted (KAV)', obj.Accepted]
                    temp[recycledIndex] = ['Accepted (HSPRD)', obj.Recycled]
                    obj = temp.reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {})

                    delete obj.Accepted
                    delete obj.Recycled
                }

                this.dem_statuses = Object.entries(this.hasUsed_globalSearch ? this.globalSearch_count : obj).map(([key, val]) => { return {label: key, val: val}})
                this.dem_statuses = this.dem_statuses.map(status => { 
                    // let infos = {
                    //     "has potential" : "Counsel's Input - Uncertain Data (Trademark class not covered, etc.)",
                    //     "for review" : "Client's Input If Counterfeit (Accepted) Or Legit (Rejected)",
                    //     "monitoring" : "On Hold",
                    //     "accepted" : "Counterfeit Sellers - Approved For Filing",
                    //     "submitted" : "Data Submitted To Counsel - Ready For Filing",
                    //     "rejected" : "Legit Sellers - Disapproved For Filing",
                    //     "for internal review" : "Need to be Reviewed Internally",
                    //     "initial review completed" : "Initial Review is Completed",
                    //     "filed" : "Filed Details",
                    //     "cases" : "Filed Cases Details"
                    // }
                    
                    /* Changed Description
                    * Deliverable Link below:
                    * https://podio.com/apmcllccom/artemis/apps/deliverables/items/395
                    * Changed Statuses are: For Review, Monitoring, Accepted, Submitted, Rejected, Cases
                    **/ 
                    let infos = {
                        "has potential" : "Counsel's Input - Uncertain Data (Trademark class not covered, etc.)",
                        "for review" : "Need Client's Input if Approved, Rejected, or Monitoring",
                        "monitoring" : "The client needs an in-depth analysis to approve or reject the data",
                        "accepted" : "Data that was confirmed by the client, was indeed a counterfeit and approved to include in the filing",
                        "submitted" : "Data that is ready for filing and approved by the client",
                        "rejected" : "Data confirmed by the client or law firm that it is not suitable for filing",
                        "for internal review" : "Need to be Reviewed Internally",
                        "initial review completed" : "Initial Review is Completed",
                        "filed" : "Filed Details",
                        "cases" : "Shows Filed Data, Case No. and Status"
                    }

                    return { ...status, info: infos[status.label.toLowerCase()] }
                })
            }).catch(err=>{console.log(err)})

            this.case_status_options = this.cases_statuses
                .filter(stuff => {
                    if (this.access_level() != 7 && stuff.type == "Internal")
                        return false
                    
                    return true
                })
                .map(stuff => {
                    let count = campaign.total_items_by_status[stuff.name?.toLowerCase().replace(/ /g,"_")] ?? 0
                    return { label: stuff.name, count: count, value: stuff.id }
                })
        }
    },
    access_level() {
        let access_level = 3

        if (localStorage.getItem("lawfirm") != null)
            return JSON.parse(localStorage.getItem("lawfirm")).access_level
            
        return access_level
    },
    hasCaseStatusAccess() {
        if ([3, 7, 6].includes(this.access_level()))
            return true;

        return this.feature_access != 0;
    },
    filterPlatforms() {
      let platforms = document.querySelectorAll('input[name=platform]')
      let keyword2 = this.filter_platform == "" ? "" : this.filter_platform.toLowerCase().trim()

      for (var index2 = 0; index2 < platforms.length; index2++) {
          if (!platforms[index2].parentElement.innerHTML.trim().toLowerCase().includes(keyword2)) {
              platforms[index2].parentElement.parentElement.style.visibility = "hidden"
              platforms[index2].parentElement.parentElement.style.padding = "0"
              platforms[index2].parentElement.parentElement.style.height = "0"
          } else {
              platforms[index2].parentElement.parentElement.style.visibility = ""
              platforms[index2].parentElement.parentElement.style.padding = ""
              platforms[index2].parentElement.parentElement.style.height = ""
          }
      }
    },
    filterPaymentTypes() {
        let paymentTypes = document.querySelectorAll('input[name=key]')
        let keyword3 = this.filter_payment_type ==  "" ? "" : this.filter_payment_type.toLowerCase().trim()

        for (var index3 = 0; index3 < paymentTypes.length; index3++) {
            if (!paymentTypes[index3].parentElement.innerHTML.trim().toLowerCase().includes(keyword3)) {
                paymentTypes[index3].parentElement.parentElement.style.visibility = "hidden"
                paymentTypes[index3].parentElement.parentElement.style.padding= "0"
                paymentTypes[index3].parentElement.parentElement.style.height = "0"
            } else {
                paymentTypes[index3].parentElement.parentElement.style.visibility = ""
                paymentTypes[index3].parentElement.parentElement.style.padding = ""
                paymentTypes[index3].parentElement.parentElement.style.height = ""
            }
        }
    },
    applyPlatformFilters() {
      // assign the scoped selected_lf_platforms to the filter object's selected_lf_platforms
      this.filters.selected_lf_platforms = this.selected_lf_platforms
      this.filters.selected_payment_types = this.selected_payment_types
    },
    urldecode(url) {
        return decodeURIComponent((url || "").replace(/\+/g, ' '));
    },
    lawfirmChangeStatus(status) {
        if(!this.hasUsed_globalSearch){
            if (this.$route.query.case_status == null)
                this.cancelOngoing();

            const temp = status

            if ([7].includes(this.access_level())) {
                switch (status) {
                    case 'Accepted (KAV)':
                        status = 'Accepted'
                        break;
                    case 'Accepted (HSPRD)':
                        status = 'Recycled'
                        break;
                    default:
                        break;
                }
            }
    
            let destination =  {
              name:"lawfirmlisting", 
              params: {
                    status      : status,
                    case_id     : this.$route.params.case_id || 0,
                    // seller_flags: this.filters.seller_flags,
                    campaign_id : this.$route.params.campaign_id,
                },
            }
    
            if (status == "Cases" && this.$route.params.case_id == 0)
                destination.query = { case_status: 0 }
            else if (this.subStatusesStore.canSeeSubStatuses)
                destination.query = { substatus_id: -1 }
    
            this.$router.push(destination)
            this.filters.status = status
        }
    },
    cancelOngoing() {
        this.cancelRequests();
        this.refreshCancelToken();
    },
    randomColorNumber() {
        return Math.floor((Math.random() * (9 - 1)) + 1)
    },
    updateCaseStatus(value) {
        this.cancelOngoing();
        this.$router.push({name: this.$route.name, params: { ...this.$route.params, status: 'Cases' }, query: { case_status: value }})
    },
    updateSubStatus(status, value) {
        // this.cancelOngoing();

        if ([7].includes(this.access_level())) {
            switch (status) {
                case 'Accepted (KAV)':
                    status = 'Accepted'
                    break;
                case 'Accepted (HSPRD)':
                    status = 'Recycled'
                    break;
                default:
                    break;
            }
        }

        this.$router.push({name: this.$route.name, params: { ...this.$route.params, status }, query: { substatus_id: value }})
        this.filters.status = status
    },
    // removeSubStatusFocus() {
    //     setTimeout(() => {
    //         $('.a-modal-status-history-con .btn.statusBorder.sub-status').removeClass('active focus')
    //     })
    // },
    // toggleSubStatus(e, id, status) {
    //     useReasonSelectionStore().resetReasonsSelectionStore();
    //     this.subStatusesStore.currentSelectedSubStatus = null

    //     let p = e.target.parentElement
    //     let count = 0

    //     while (!$(p).hasClass('status-change-options') && count < 6) {
    //         p = p.parentElement
    //         count++
    //     }

    //     setTimeout(() => {
    //         $(p).children().first().addClass('active focus')
    //     })

    //     this.status = status
    //     this.subStatusesStore.currentSelectedSubStatus = id
    // },
    getHeight() {
        return this.$route.name == 'lawfirmcounterfeitchecker' 
            ? 'height: calc(100vh - 95px)'
            : this.filters.status != 'Cases' || (this.filters.status == 'Cases' && this.$route.params.case_id == 'undefined' || this.$route.params.case_id != 0)
                ? 'height: calc(100vh - 147px)' 
                : 'height: calc(100vh - 178px)'
    },
    async getCaseStatuses() {
        let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
        await axios.get(`${process.env.VUE_APP_URL}/case_statuses?page_size=1000`, headers)
            .then(({ data }) => this.cases_statuses = data.data)
    },
    filterPlatformDataBreakdown() {
        let platformDataBreakdownAccordion = document.getElementById('platformDataBreakdownAccordion')
        // console.log(platformDataBreakdownAccordion.children)
        if (this.selected_platform_data_breakdown != 0 && platformDataBreakdownAccordion != null) {
            for (const card of platformDataBreakdownAccordion.children)
                {
                    if (card.id == this.selected_platform_data_breakdown) {
                        card.style.display = ""
                        card.style.visibility = ""
                        card.style.height = ""
                    } else {
                        card.style.display = "none"
                        card.style.visibility = "hidden"
                        card.style.height = "0"                        }
                    }
            return
        }
        if (platformDataBreakdownAccordion != null && typeof platformDataBreakdownAccordion != "undefined") {
            for(const card of platformDataBreakdownAccordion.children)
                {
                    card.style.display = ""
                    card.style.visibility = ""
                    card.style.height = ""
                }
        }
    },
    remove_noAccountLabel(payment_type){
        let paypal_counter = 0
        let loweredCase_payment_type = payment_type.toString().toLowerCase()
        if(loweredCase_payment_type.includes('no account')){
            let splitted_type = payment_type.split(' ')
            payment_type = splitted_type[0]
        }
        return payment_type
    },
  },
  watch: {
    shown_data(val) {
        this.updateShown(val)
    },
    '$route.name': {
        handler() {
            this.initializeStatuses(true)
            this.seller_flags = []
            this.payment_methods = []
            
            if(this.$route.name == "lawfirmlisting"){
                this.status = this.$route.params.status
                this.filters.status = this.$route.params.status
                this.setFilters(this.filters)
                
                if (this.$route.params.campaign_id != null) {
                    this.getCounsels()
                    // this.getLfPlatforms()
                    this.getPaymentTypes()
                    this.getCaseSellerAccounts()
                    this.getPlatformDataBreakdown()
                }

                if (this.$route.params.campaign_id != 0 && this.$route.params.case_id != 0) {
                    this.getSellerFlags()
                }
            }
            
            if (this.$route.name == "lawfirmcounterfeitchecker") this.getQflags()
        },
        deep:true
    },
    '$route.params.campaign_id'(val, oldVal) {
        this.uncheckPlatforms()
        this.initializeStatuses(true)
        this.uncheckPaymentTypes()

        if (val != oldVal) this.dem_statuses = []
    },
    filter_platform() {
        this.filterPlatforms()
    },
    filter_payment_type() {
        this.filterPaymentTypes()
    },
    'filters': {
        handler(val){
            if(typeof this.$route.params.campaign_id != 'undefined'){
                this.setFilters(val)
            }
        },
        deep: true
    },
    currentUser(val) {
        if (JSON.stringify(val) != JSON.stringify(this.user)) {
            this.user = val
            this.dem_statuses = []
        }
    },
    'ccStatuses.selected'(val) {
        this.changeFilterOrOrder(val, 'statuses')
    },
    withActive(val) {
        this.changeFilterOrOrder(val, 'withActive')
    },
    hasUploadedCaseFile(new_val) {
        if(new_val){
            this.status = this.$route.params.status
            this.filters.status = this.$route.params.status
            this.getCaseSellerAccounts()
            this.getPlatformDataBreakdown()
            this.user = this.getCurrentUser()
            this.getSellerFlags()

            this.initializeStatuses(true)
            this.$store.dispatch('uploadedCaseFile', false)
        }
    },
    hasDeletedCaseFile(new_val) {
        if(new_val){
            this.status = this.$route.params.status
            this.filters.status = this.$route.params.status
            this.getCaseSellerAccounts()
            this.getPlatformDataBreakdown()
            this.user = this.getCurrentUser()
            this.getSellerFlags()

            this.initializeStatuses(true)
            this.$store.dispatch('deletedCaseFile', false)
        }
    },
    selected_platform_data_breakdown() {
        this.filterPlatformDataBreakdown()
    },
    hasUsed_globalSearch(new_val) {
        if(new_val){
            this.status = this.$route.params.status
            this.filters.status = this.$route.params.status
            this.getCaseSellerAccounts()
            this.getPlatformDataBreakdown()
            this.user = this.getCurrentUser()
            this.getSellerFlags()

            this.initializeStatuses(true)
            this.$store.dispatch('deletedCaseFile', false)
        }else {
            this.status = this.$route.params.status
            this.filters.status = this.$route.params.status
            this.getCaseSellerAccounts()
            this.getPlatformDataBreakdown()
            this.user = this.getCurrentUser()
            this.getSellerFlags()

            this.initializeStatuses(true)
            this.$store.dispatch('deletedCaseFile', false)

            this.uncheckPlatforms()
            this.uncheckPaymentTypes()
        }
    },
    globalSearch_listings() {
        this.getPlatformDataBreakdown()
        this.getCaseSellerAccounts()
        
        this.status = this.$route.params.status
        this.filters.status = this.$route.params.status
        this.getCaseSellerAccounts()
        this.getPlatformDataBreakdown()
        this.user = this.getCurrentUser()
        this.getSellerFlags()

        this.initializeStatuses(true)
        this.$store.dispatch('deletedCaseFile', false)

        this.uncheckPlatforms()
        this.uncheckPaymentTypes()
    },
    restrictedFeatures() {
        this.initializeStatuses();
        this.getSellerFlags();
    },
    case_seller_accounts_vuex: {
        handler(newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.getCaseSellerAccounts();
            }
        },
        deep: true
    }
  }
}
</script>

<style scoped>
/*
*   SIDEBAR
*/
.a-sidebar-filter {
    -webkit-transition: top .5s ease, margin .5s ease;
    transition: top .5s ease, margin .5s ease;
    box-shadow: none;
    width: 323px;
    top: 57px;
    right: 0;
}

/* .a-sidebar.a-sidebar-filter {
    padding-bottom: 60px;
} */

.a-sidebar-filter-header {
    /* text-transform: uppercase; */
    font-weight: 700;
    color: #383A3D;
    font-size: 12px;
}

.a-sidebar-filter-header-icon {
    display: inline-block;
    height: 18px;
    width: 14px;
    position: relative;
    top: -2px;
}

.a-nav-filter-item {
    padding-bottom: 11px;
    padding-left: 39px;
    padding-top: 11px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
}

.custom-control label.custom-control-label {
    color: #515365;
    font-weight: 400;
    font-size: 14px;
}

.cases-button {
    position: fixed;
    bottom: 40px;
    right: 100px;
}

.apply-filter-button {
    position: fixed;
    bottom: 12px;
    right: 36px;
    width: 250px;
}

.btn-group-vertical i.fa-caret-down {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);  
    z-index: 2; 
}

.btn-research-status.sub-status {
    position: relative;
}

.btn-research-status.sub-status::before {
    content:"";
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #383A3D;
}
/*
*   END OF SIDEBAR
*/
</style>
