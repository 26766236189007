<template>
    <div class="sidebar" style="padding-bottom: 80px;">
      <template v-if="['adminreasons', 'adminsellerlistings','admindummies'].includes($route.name)">
          <template v-if="!['admindummies'].includes($route.name)">
              <h4 
                  id="filter-research-status"
                  style="cursor:pointer;"
                  class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                  data-toggle="collapse"
                  data-target="#collapseStatus"
                  title="Click to show/hide research statuses">
                  Research Status <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseStatus" aria-labelledby="filter-research-status">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item" v-for="(status, index) in admin_statuses" :key="index">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" :id="`status-${status.id}`"
                                      name="status" :value="status.id" :checked="$route.query.status == status.id" @change="$router.push({'query': Object.assign({},$route.query,{'status':status.id})})">
                                  <label :for="`status-${status.id}`" class="custom-control-label">{{status.status == "Recheck" ? "For Rework" : status.status == "Invalid" ? "Listing Removal" : status.status }}</label>
                              </div>
                          </li>           
                      </ul>
                  </div>
              </nav>

              <!-- <h4
                v-show="$route.query.status == 2"
                id="for-rework-status-filter"
                style="cursor:pointer;"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseForReworkFilters"
                title="Click to show/hide for rework filters"
              >
                For Rework Filter type here to continue <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav v-show="$route.query.status == 2" class="mt-2 collapse show" id="collapseForReworkFilters" aria-labelledby="for-rework-status-filter">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                          <li class="nav-item a-nav-filter-item" v-for="(type, index) in for_rework_filters" :key="index">
                              <div class="for-rework-status-filter" data-toggle="collapse" data-target="#collapseNotifyTeamLead">
                                      <img src="/static/svg/Main-feature-active.svg" style="height: 15px; width: 15px;" :title="type.label">
                                    <label :for="`status-${type.id}`" style="margin-left: 10px; color: #515365; font-weight: 400; font-size: 14px;"
                                    >
                                        {{ type.label }}
                                    </label>
                              </div>
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" id="collapseNotifyTeamLead">
                                    <li class="nav-item a-nav-filter-item" v-for="(notify, index) in type.choices" :key="index">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" :id="`status-${notify.value}`"
                                                name="status" :value="notify.value" :checked="$route.query.type == notify.value" 
                                                @change="$router.push({'query': Object.assign({},$route.query,{'status': $route.query.status, 'type':notify.value})})">
                                            <label :for="`status-${notify.id}`" class="custom-control-label">{{ notify.label }}</label>
                                        </div>
                                    </li>
                                </ul>
                          </li>
                      </ul>
                  </div>
              </nav> -->

              <h4
                id="more-filter-reasearch-status"
                style="cursor:pointer;"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseFilters"
                title="Click to show/hide status type filters"
              >
                Status Type <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseFilters" aria-labelledby="more-filter-reasearch-status">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item" v-for="(type, index) in reasons_filters_types" :key="index">
                              <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" :id="`type-${type.value}`"
                                      name="type" :value="type.value" :checked="$route.query.type == type.value" 
                                      @change="$router.push({'query': Object.assign({},$route.query,{'status': $route.query.status, 'type':type.value})})">
                                    <label :for="`type-${type.value}`" class="custom-control-label">{{ type.label }}</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
              <h4
                id="lfc-invalidation-filter"
                style="cursor: pointer"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseLFCFilter"
                title="Click to show/hide lfc invalidation filters"
                >
                Client-specific Reasons <i class="fas fa-chevron-down ml-2"></i>
                </h4>

                <nav class="mt-2 collapse show" id="collapseLFCFilter" aria-labelledby="lf-invalidation-filter">
                    <div style="min-height: 100px">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li class="nav-item a-nav-filter-item" v-for="(type, index) in lfc_validation" :key="index">
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" :id="`is_for_clients-${type.value}`"
                                        name="is_for_clients" :value="type.value" :checked="$route.query.is_for_clients == type.value"
                                        @change="$router.push({'query': Object.assign({}, $route.query,{'status': $route.query.status, 'type': $route.query.type, 'is_for_clients': type.value})})">
                                        <label :for="`is_for_clients-${type.value}`" class="custom-control-label"> {{ type.label }} </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
          </template>

          <template v-if="!['adminreasons'].includes($route.name)">
              <h4 
                  class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                  style="cursor:pointer;"
                  :style="`${$route.name != 'admindummies' ? 'border-top: 1px solid #eee' : ''}`"
                  id="filter-platform"
                  data-toggle="collapse"
                  data-target="#collapsePlatform"
                  title="Click to show/hide Platforms">
                  Platforms <i class="fas fa-chevron-down ml-2"></i>
              </h4>
              <nav class="mt-2 collapse" id="collapsePlatform" aria-labelledby="filter-research-platform">
                  <div style="min-height: 100px">
                      <div v-if="!['admindummies'].includes($route.name)">
                          <ar-textfield
                              icon="fas fa-search"
                              :class="`ml-3`"
                              placeholder="Filter platforms"
                              v-model="searched_admin_platform"
                          />
                      </div>
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                          <template v-if="!['admindummies'].includes($route.name)">
                              <li class="nav-item a-nav-filter-item" v-for="(platform,index) in filtered_admin_platforms" :key="index">
                                  <div class="custom-control " 
                                      :class="{'custom-radio':['adminsellers'].includes($route.name),'custom-checkbox':false}"
                                  >
                                      <input class="custom-control-input" 
                                          :type="['adminsellers'].includes($route.name) ? 'radio' : 'checkbox'" 
                                          name="platform" 
                                          :id="`platform-${platform.value}`" 
                                          :value="platform.value"
                                          :checked="$route.query.platform == platform.value"
                                          @change="$router.push({'query': Object.assign({},$route.query,{platform:platform.value})})">
                                      <label :for="`platform-${platform.value}`" class="custom-control-label">{{platform.label}}</label>
                                  </div>
                                  
                              </li>
                          </template>
                          <!-- Show Radio button instead of checkbox in Dummy Account Management-->
                          <template v-else>
                              <li class="nav-item a-nav-filter-item">
                                  <div class="custom-control custom-radio">
                                      <input class="custom-control-input" type="radio" :id="`platform-all`"
                                          name="platform" value="all" :checked="$route.query.platform == 'all'" @change="$router.push({'query': Object.assign({},$route.query,{platform:'all'})})">
                                      <label :for="`platform-all`" class="custom-control-label">All</label>
                                  </div>
                              </li>

                              <li class="nav-item a-nav-filter-item" v-for="(platform,index) in admin_radio_platforms" :key="index">
                                  <div class="custom-control custom-radio">
                                      <input class="custom-control-input" type="radio" :id="`platform-${platform}`"
                                          name="platform" :value="platform" :checked="platform.toLowerCase().includes($route.query.platform.toLowerCase())" @change="$router.push({'query': Object.assign({},$route.query,{platform})})">
                                      <label :for="`platform-${platform}`" class="custom-control-label">{{platform}}</label>
                                  </div>
                              </li>
                          </template>
                      </ul>
                  </div>
              </nav>
          </template>
          <!--
              Added Filter By filter here since I can't use the Filter by filter below becuase of condition
              -Dave 4.24.2020
          -->
          <template v-if="['admindummies'].includes($route.name)">
              <h4 
                  id="filter-sort-by"
                  :style="`cursor:pointer; ${$route.name == 'adminartemisusers' ? 'border-top: 1px solid #eee' : ''}`"
                  class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                  data-toggle="collapse"
                  data-target="#collapseSort"
                  title="Click to show/hide sort by">
                  Filter By <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-all"
                                      name="filterby" value="all" @change="$router.push({'query': Object.assign({},$route.query,{'status':'all'})})" :checked="$route.query.status === 'all'">
                                  <label for="sort-all" class="custom-control-label">All</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-active"
                                      name="filterby" value="active" @change="$router.push({'query': Object.assign({},$route.query,{'status':'active'})})" :checked="$route.query.status === 'active'">
                                  <label for="sort-active" class="custom-control-label">Active</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-inactive"
                                      name="filterby" value="inactive" @change="$router.push({'query': Object.assign({},$route.query,{'status':'inactive'})})" :checked="$route.query.status === 'inactive'">
                                  <label for="sort-inactive" class="custom-control-label">Inactive</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </template>

          <template v-if="['admindummies'].includes($route.name)">
              <h4 
                  id="filter-sort-by"
                  :style="`cursor:pointer; ${$route.name == 'adminartemisusers' ? 'border-top: 1px solid #eee' : ''}`"
                  class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                  data-toggle="collapse"
                  data-target="#collapseOrder"
                  title="Click to show/hide sort by">
                  Order By <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseOrder" aria-labelledby="filter-sort-by">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                        
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="order-asc"
                                      name="orderby" value="asc" @change="$router.push({'query': Object.assign({},$route.query,{'order':'asc'})})" :checked="$route.query.order === 'asc'">
                                  <label for="order-asc" class="custom-control-label">Ascending</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="order-desc"
                                      name="orderby" value="desc" @change="$router.push({'query': Object.assign({},$route.query,{'order':'desc'})})" :checked="$route.query.order === 'desc'">
                                  <label for="order-desc" class="custom-control-label">Descending</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </template>
      </template>

      <template v-else>
          <template v-if="['adminartemisusers', 'adminenvaccessmanagement'].includes($route.name)">
              <h4 
                  class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                  style="cursor:pointer;"
                  id="filter-user-types"
                  data-toggle="collapse"
                  data-target="#collapseUserTypes"
                  title="Click to show/hide user types">
                  User Type <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseUserTypes" aria-labelledby="filter-user-types">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item" v-for="(type,index) in admin_user_types" :key="index">
                              <div class="custom-control custom-radio">
                                  <input @change="changeFilterOrOrder(type.value, 'type')" class="custom-control-input" type="radio" :id="`user-type-${type.value}`" name="usertypes" :value="type.value" :checked="$route.query.type == type.value || (type.value === -1 && $route.query.type === undefined)" />
                                  <label :for="`user-type-${type.value}`" class="custom-control-label">{{type.label}}</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </template>

          <template v-if="$route.name === 'adminenvaccessmanagement'">
              <h4 
                  id="filter-environment"
                  :style="`cursor:pointer; ${$route.name == 'adminartemisusers' ? 'border-top: 1px solid #eee' : ''}`"
                  class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                  data-toggle="collapse"
                  data-target="#collapseEnvironment"
                  title="Click to show/hide environment">
                  Environment <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseEnvironment" aria-labelledby="filter-environment">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="env-all"
                                      name="env" value="all" @change="changeFilterOrOrder(-1, 'env')" :checked="$route.query.env == -1 || $route.query.env === undefined">
                                  <label for="env-all" class="custom-control-label">All</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="env-beta"
                                      name="envs" value="beta" @change="changeFilterOrOrder('beta', 'env')" :checked="$route.query.env == 'beta'">
                                  <label for="env-beta" class="custom-control-label">Beta</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="env-stage"
                                      name="envs" value="stage" @change="changeFilterOrOrder('stage', 'env')" :checked="$route.query.env == 'stage'">
                                  <label for="env-stage" class="custom-control-label">Stage</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </template>

          <template v-if="$route.name == 'adminsellers'">
              <h4 
                  id="filter-counsel"
                  style="cursor:pointer; border-top: 1px solid #eee"
                  class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
              >
              Show Whitelisted Sellers
              </h4>

              <nav class="mt-2 collapse show" id="collapseCounsel" aria-labelledby="filter-counsel">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item" >
                              <div class="custom-control custom-checkbox">
                                  <input
                                      v-model ="show_whitelist"
                                      id ="show-whitelist-sellers"
                                      class ="custom-control-input"
                                      type ="checkbox"
                                      name ="counsel"
                                  >
                                  <label for="show-whitelist-sellers" class="custom-control-label">Whitelist Sellers</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </template>

            <template v-if="includedRoutes_forItemClassManagement">

                <h4 
                    id="filter-show-by"
                    :style="`cursor:pointer;`"
                    class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                    data-toggle="collapse"
                    data-target="#admin-classification-showAll"
                    title="Click to show/hide sort by">
                    Show All <i class="fas fa-chevron-down ml-2"></i>
                </h4>

                <nav class="mt-2 collapse show" id="admin-classification-showAll" aria-labelledby="filter-show-by">
                    <div style="min-height: 100px">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-item a-nav-filter-item" v-for="(item, index) in classification_management_showAll" :key="index">
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" :id="`classification-show-${index}`"
                                        name="showAllby" :value="item.value" @change="changeFilterOrOrder(item.value, 'type')" :checked="$route.query.type == item.value || (item.value === 0 && $route.query.type === undefined)">
                                    <label :for="`classification-show-${index}`" class="custom-control-label">{{item.label}}</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
                <template v-if="$route.name != 'adminitemcampaigns'">
                    <h4 
                        id="filter-sort-by"
                        :style="`cursor:pointer;`"
                        class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                        data-toggle="collapse"
                        data-target="#admin-classification-collapseSort"
                        title="Click to show/hide sort by">
                        Filter By <i class="fas fa-chevron-down ml-2"></i>
                    </h4>

                    <nav class="mt-2 collapse show" id="admin-classification-collapseSort" aria-labelledby="filter-sort-by">
                        <div style="min-height: 100px">
                            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                data-accordion="false">
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="filter-classification-all"
                                            name="filterby" value="all" @change="changeFilterOrOrder(-1, 'status')" :checked="$route.query.status == -1">
                                        <label for="filter-classification-all" class="custom-control-label">All</label>
                                    </div>
                                </li>
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="filter-classification-active"
                                            name="filterby" value="active" @change="changeFilterOrOrder(1, 'status')" :checked="$route.query.status == 1 || $route.query.status === undefined">
                                        <label for="filter-classification-active" class="custom-control-label">Active</label>
                                    </div>
                                </li>
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="filter-classification-inactive"
                                            name="filterby" value="inactive" @change="changeFilterOrOrder(0, 'status')" :checked="$route.query.status == 0">
                                        <label for="filter-classification-inactive" class="custom-control-label">Inactive</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </template>
            </template>

            <template v-if="$route.name == 'adminFeatures'">
            <!--TODO: Add filter drawer options for feature access -->
                <h4 
                    id="filter-sort-by"
                    style="cursor:pointer"
                    class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                    data-toggle="collapse"
                    data-target="#collapseSort"
                    title="Click to show/hide sort by">
                    Filter By <i class="fas fa-chevron-down ml-2"></i>
                </h4>

                <nav class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                    <div style="min-height: 100px">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-item a-nav-filter-item">
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" id="sort-all"
                                        name="filterby" value="all" @change="changeFilterOrOrder('all', 'gui')" :checked="$route.query.gui === 'all' || $route.query.gui === undefined">
                                    <label for="sort-all" class="custom-control-label">All GUIs</label>
                                </div>
                            </li>
                            <li class="nav-item a-nav-filter-item">
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" id="sort-admin"
                                        name="filterby" value="admin" @change="changeFilterOrOrder('admin', 'gui')" :checked="$route.query.gui === 'admin'">
                                    <label for="sort-admin" class="custom-control-label">Admin</label>
                                </div>
                            </li>
                            <li class="nav-item a-nav-filter-item">
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" id="sort-ver"
                                        name="filterby" value="ver" @change="changeFilterOrOrder('ver', 'gui')" :checked="$route.query.gui === 'ver'">
                                    <label for="sort-ver" class="custom-control-label">Verification</label>
                                </div>
                            </li>
                            <li class="nav-item a-nav-filter-item">
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" id="sort-cm"
                                        name="filterby" value="cm" @change="changeFilterOrOrder('cm', 'gui')" :checked="$route.query.gui === 'cm'">
                                    <label for="sort-cm" class="custom-control-label">Case Management</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>

                <h4 
                    id="filter-active-status"
                    style="cursor:pointer"
                    class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                    data-toggle="collapse"
                    data-target="#activeStatus"
                    title="Click to show/hide sort by">
                    Sort by <i class="fas fa-chevron-down ml-2"></i>
                </h4>

                <nav class="mt-2 collapse show" id="activeStatus" aria-labelledby="filter-active-status">
                    <div style="min-height: 100px">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-item a-nav-filter-item">
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" id="sort-all-active-status"
                                        name="sortby" value="all" @change="changeFilterOrOrder(-1, 'sortby')" :checked="$route.query.sortby == -1 || $route.query.sortby === undefined">
                                    <label for="sort-all-active-status" class="custom-control-label">All</label>
                                </div>
                            </li>
                            <li class="nav-item a-nav-filter-item">
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" id="sort-active"
                                        name="sortby" value="active" @change="changeFilterOrOrder(1, 'sortby')" :checked="$route.query.sortby == 1">
                                    <label for="sort-active" class="custom-control-label">Active</label>
                                </div>
                            </li>
                            <li class="nav-item a-nav-filter-item">
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" id="sort-inactive"
                                        name="sortby" value="inactive" @change="changeFilterOrOrder(0, 'sortby')" :checked="$route.query.sortby == 0">
                                    <label for="sort-inactive" class="custom-control-label">Inactive</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
          </template>

          <template v-if="excludedRoutes">
              <h4 
                  id="filter-sort-by"
                  :style="`cursor:pointer; ${$route.name == 'adminartemisusers' ? 'border-top: 1px solid #eee' : ''}`"
                  class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                  data-toggle="collapse"
                  data-target="#collapseSort"
                  title="Click to show/hide sort by">
                  Filter By <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-all"
                                      name="filterby" value="all" @change="changeFilterOrOrder(-1, 'status')" :checked="$route.query.status == -1">
                                  <label for="sort-all" class="custom-control-label">All</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-active"
                                      name="filterby" value="active" @change="changeFilterOrOrder(1, 'status')" :checked="$route.query.status == 1 || $route.query.status === undefined">
                                  <label for="sort-active" class="custom-control-label">Active</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-inactive"
                                      name="filterby" value="inactive" @change="changeFilterOrOrder(0, 'status')" :checked="$route.query.status == 0">
                                  <label for="sort-inactive" class="custom-control-label">Inactive</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </template>

          <template v-if="['adminweeklyreport', 'admincampaignweeklyreport'].includes($route.name)">
             <h4
                v-if="$route.name == 'admincampaignweeklyreport'"
                id="filter-weekly-sort"
                style="cursor:pointer"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseEmpty"
                title="Click to show/hide filter"
              >
                Filters <i class="fas fa-chevron-down ml-2"></i>
             </h4>
             <nav v-if="$route.name == 'admincampaignweeklyreport'" class="mt-2 collapse show" id="collapseEmpty" aria-labelledby="filter-weekly-sort">
                <div style="min-height: 50px">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-checkbox disabled" @click="setHideEmptyCampaigns()">
                                <input
                                    class="custom-control-input"
                                    type="checkbox"
                                    name="campaign"
                                    :id="`campaign-all`"
                                    :checked="$route.query.hideEmpty == 'true'"
                                >
                                <label :for="`campaign-hidden`" class="custom-control-label">Hide Empty Campaigns</label>
                            </div>
                            <!-- <div class="custom-control custom-checkbox">
                                  <input
                                      v-model ="hideEmptyCampaigns"
                                      id ="hide-empty-campaigns"
                                      class ="custom-control-input"
                                      type ="checkbox"
                                      name ="hide-empty-campaigns"
                                      @change="$router.push({'query': Object.assign({},$route.query,{'hideEmpty': hideEmptyCampaigns})})"
                                    >
                                  <label for="hide-empty-campaigns" class="custom-control-label">Hide Empty Campaigns</label>
                              </div> -->
                        </li>
                    </ul>
                </div>
             </nav>

             <h4 
                  v-if="$route.name == 'admincampaignweeklyreport'"
                  id="filter-sort-by-campaign"
                  style="cursor:pointer"
                  class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                  data-toggle="collapse"
                  data-target="#collapseSort"
                  title="Click to show/hide sort by">
                  Order By <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav v-if="$route.name == 'admincampaignweeklyreport'" class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by-campaign">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-all"
                                      name="filterby" value="all" @change="changeFilterOrOrder('name', 'sort')" :checked="$route.query.sort == 'name' || $route.query.status === undefined">
                                  <label for="sort-all" class="custom-control-label">Campaign Name</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-active"
                                      name="filterby" value="active" @change="changeFilterOrOrder('assigned_users', 'sort')" :checked="$route.query.sort == 'assigned_users'">
                                  <label for="sort-active" class="custom-control-label">Assigned Users</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>

            <h4 
                v-if="$route.name == 'admincampaignweeklyreport'"
                id="filter-order-by"
                style="cursor:pointer;"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseOrder"
                title="Click to show/hide order by">
                Sort By <i class="fas fa-chevron-down ml-2"></i>
            </h4>

            <nav v-if="$route.name == 'admincampaignweeklyreport'" class="mt-2 collapse show" id="collapseOrder" aria-labelledby="filter-order-by">
                <div style="min-height: 100px">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="order-asc"
                                    name="orderby" value="asc" @change="changeFilterOrOrder('asc', 'order')" :checked="$route.query.order === 'asc' || $route.query.order === undefined">
                                <label for="order-asc" class="custom-control-label">Ascending</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="order-desc"
                                    name="orderby" value="desc" @change="changeFilterOrOrder('desc', 'order')" :checked="$route.query.order === 'desc'">
                                <label for="order-desc" class="custom-control-label">Descending</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

              <h4 
                  v-if="$route.name == 'adminweeklyreport'"
                  id="filter-sort-by"
                  style="cursor:pointer"
                  class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                  data-toggle="collapse"
                  data-target="#collapseSort"
                  title="Click to show/hide sort by">
                  Sort By <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav v-if="$route.name == 'adminweeklyreport'" class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-all"
                                      name="filterby" value="all" @change="changeFilterOrOrder('name', 'sort')" :checked="$route.query.sort == 'name' || $route.query.status === undefined">
                                  <label for="sort-all" class="custom-control-label">Name</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-active"
                                      name="filterby" value="active" @change="changeFilterOrOrder('access_level', 'sort')" :checked="$route.query.sort == 'access_level'">
                                  <label for="sort-active" class="custom-control-label">Access Level</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </template>

          <template v-if="$route.name == 'adminUserRestrictions'">
            <h4 
                  class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                  style="cursor:pointer;"
                  id="filter-user-types"
                  data-toggle="collapse"
                  data-target="#collapseUserTypes"
                  title="Click to show/hide user types">
                  User Type <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseUserTypes" aria-labelledby="filter-user-types">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item" v-for="(type,index) in admin_user_types" :key="index">
                              <div class="custom-control custom-radio">
                                  <input @change="changeFilterOrOrder(type.value, 'type')" class="custom-control-input" type="radio" :id="`user-type-${type.value}`" name="usertypes" :value="type.value" :checked="$route.query.type == type.value || (type.value === -1 && $route.query.type === undefined)" />
                                  <label :for="`user-type-${type.value}`" class="custom-control-label">{{type.label}}</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>

              <h4 
                  id="filter-sort-by"
                  :style="`cursor:pointer; ${$route.name == 'adminartemisusers' ? 'border-top: 1px solid #eee' : ''}`"
                  class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                  data-toggle="collapse"
                  data-target="#collapseSort"
                  title="Click to show/hide sort by">
                  Sort By <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-all"
                                      name="filterby" value="all" @change="changeFilterOrOrder(-1, 'status')" :checked="$route.query.status == -1">
                                  <label for="sort-all" class="custom-control-label">All</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-active"
                                      name="filterby" value="active" @change="changeFilterOrOrder(1, 'status')" :checked="$route.query.status == 1 || $route.query.status === undefined">
                                  <label for="sort-active" class="custom-control-label">Active</label>
                              </div>
                          </li>
                          <li class="nav-item a-nav-filter-item">
                              <div class="custom-control custom-radio">
                                  <input class="custom-control-input" type="radio" id="sort-inactive"
                                      name="filterby" value="inactive" @change="changeFilterOrOrder(0, 'status')" :checked="$route.query.status == 0">
                                  <label for="sort-inactive" class="custom-control-label">Inactive</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </template>

          <template v-if="$route.name == 'adminRoleBasedRestrictions'">
            <h4 
                  class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                  style="cursor:pointer;"
                  id="filter-user-types"
                  data-toggle="collapse"
                  data-target="#collapseUserTypes"
                  title="Click to show/hide user types">
                  User Type <i class="fas fa-chevron-down ml-2"></i>
              </h4>

              <nav class="mt-2 collapse show" id="collapseUserTypes" aria-labelledby="filter-user-types">
                  <div style="min-height: 100px">
                      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                          data-accordion="false">
                          <li class="nav-item a-nav-filter-item" v-for="(type,index) in admin_user_types" :key="index">
                              <div class="custom-control custom-radio">
                                  <input @change="changeFilterOrOrder(type.value, 'type')" class="custom-control-input" type="radio" :id="`user-type-${type.value}`" name="usertypes" :value="type.value" :checked="$route.query.type == type.value || (type.value === -1 && $route.query.type === undefined)" />
                                  <label :for="`user-type-${type.value}`" class="custom-control-label">{{type.label}}</label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </template>
        
        
          <h4 
              v-if="$route.name != 'admincampaignweeklyreport'"
              id="filter-order-by"
              style="cursor:pointer;"
              class="a-sidebar-filter-header pl-4 pr-4 pt-2"
              data-toggle="collapse"
              data-target="#collapseOrder"
              title="Click to show/hide order by">
              Order By <i class="fas fa-chevron-down ml-2"></i>
          </h4>

          <nav v-if="$route.name != 'admincampaignweeklyreport'" class="mt-2 collapse show" id="collapseOrder" aria-labelledby="filter-order-by">
              <div style="min-height: 100px">
                  <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                      data-accordion="false">
                      <li class="nav-item a-nav-filter-item">
                          <div class="custom-control custom-radio">
                              <input class="custom-control-input" type="radio" id="order-asc"
                                  name="orderby" value="asc" @change="changeFilterOrOrder('asc', 'order')" :checked="$route.query.order === 'asc' || $route.query.order === undefined">
                              <label for="order-asc" class="custom-control-label">Ascending</label>
                          </div>
                      </li>
                      <li class="nav-item a-nav-filter-item">
                          <div class="custom-control custom-radio">
                              <input class="custom-control-input" type="radio" id="order-desc"
                                  name="orderby" value="desc" @change="changeFilterOrOrder('desc', 'order')" :checked="$route.query.order === 'desc'">
                              <label for="order-desc" class="custom-control-label">Descending</label>
                          </div>
                      </li>
                  </ul>
              </div>
          </nav>

          <template v-if="$route.name == 'adminwhitelists'">
            <h4 
                id="filter-campaign"
                style="cursor:pointer;"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapseCampaign"
                title="Click to show/hide campaign">
                Campaign <i class="fas fa-chevron-down ml-2"></i>
            </h4>

            <nav class="mt-2 collapse show" id="collapseCampaign" aria-labelledby="filter-campaign">
                <div style="min-height: 100px">
                    <ar-textfield
                        icon="fas fa-search"
                        :class="`ml-3 mr-3 mb-0`"
                        placeholder="Search campaigns"
                        v-model="whitelisted_seller_filters.campaign.search"
                    />
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-checkbox disabled" @click="resetWhitelistedFilter()">
                                <input class="custom-control-input" type="checkbox" name="campaign" :id="`campaign-all`" :checked="!whitelisted_seller_filters.campaign.selected.length" disabled>
                                <label :for="`campaign-all`" class="custom-control-label">All Campaigns</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item" v-for="(campaign,index) in filtered_whitelists_campaigns" :key="index">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" name="campaign" :id="`admin-sidebar--campaign-${campaign.value}`" v-model="whitelisted_seller_filters.campaign.selected" :value="campaign.value">
                                <label :for="`admin-sidebar--campaign-${campaign.value}`" class="custom-control-label">{{campaign.label}}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            <h4 
                id="filter-platforms"
                style="cursor:pointer;"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapsePlatforms"
                title="Click to show/hide platforms">
                Platforms <i class="fas fa-chevron-down ml-2"></i>
            </h4>

            <nav class="mt-2 collapse show" id="collapsePlatforms" aria-labelledby="filter-platforms">
                <div style="min-height: 100px">
                    <ar-textfield
                        icon="fas fa-search"
                        :class="`ml-3 mr-3 mb-0`"
                        placeholder="Search platforms"
                        v-model="whitelisted_seller_filters.platform.search"
                    />
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-checkbox disabled" @click="resetWhitelistedFilter('platform')">
                                <input class="custom-control-input" type="checkbox" name="platform" :id="`platform-all`" :checked="!whitelisted_seller_filters.platform.selected.length" disabled>
                                <label :for="`platform-all`" class="custom-control-label">All Platforms</label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item" v-for="(platform,index) in filtered_whitelists_platforms" :key="index">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" name="platform" :id="`admin-sidebar--platform-${platform.value}`" v-model="whitelisted_seller_filters.platform.selected" :value="platform.value">
                                <label :for="`admin-sidebar--platform-${platform.value}`" class="custom-control-label">{{platform.label}}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
          </template>
          <template v-if="['admindatapriority'].includes($route.name)">
            <h4
                id="filter-datapriority-status"
                style="cursor: pointer"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapsePriorityStatus"
                title="Click to show/hide statuses"
            >
                Status <i class="fas fa-chevron-down ml-2"></i>
            </h4>
            <nav class="mt-2 collapse show" id="collapsePriorityStatus" aria-labelledby="filter-datapriority-status">
                <div style="min-height: 100px">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li class="nav-item a-nav-filter-item" v-for="(status, index) in admin_cm_statuses" :key="index">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" name="prioStatus" :id="`prio-sidebar--status-${status.id}`"
                                        v-model="data_priority_filters.status.selected" :value="status.id" @change="filterDataPriorityByStatuses()">
                                <label :for="`prio-sidebar--status-${status.id}`" class="custom-control-label"> {{ status.status }} </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            <h4
                id="filter-data-priority-campaign"
                style="cursor: pointer"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapsePriorityCampaigns"
                title="Click to show/hide campaigns"
            >
                Campaigns <i class="fas fa-chevron-down" ml-2></i>
            </h4>
            <nav class="mt-2 collapse show" id="collapsePriorityCampaigns" aria-labelledby="filter-data-priority-campaign">
                <div style="height: fit-content; max-height: 350px; overflow: auto;">
                    <ar-textfield 
                        icon="fas fa-search"
                        :class="`ml-3 mr-3 mb-0`"
                        placeholder="Search Campaigns"
                        v-model="data_priority_filters.campaign.search"
                    />
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-checkbox disabled">
                                <input class="custom-control-input" type="checkbox" name="prioCampaign" :id="`prioCampaign-all`" :checked="!data_priority_filters.campaign.selected.length" disabled>
                                <label :for="`prioCampaign-all`" class="custom-control-label"> All Campaigns </label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item" v-for="(campaign, index) in filtered_prio_campaigns" :key="index">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" name="prioCampaign" :id="`prio-sidebar-campaign-${campaign.id}`"
                                    v-model="data_priority_filters.campaign.selected" :value="campaign.id" @change="filterDataPriorityByCampaigns()">
                                <label :for="`prio-sidebar-campaign-${campaign.id}`" class="custom-control-label"> {{ campaign.name }} </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            <h4
                id="filter-data-priority-platform"
                style="cursor:pointer"
                class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                data-toggle="collapse"
                data-target="#collapsePriorityPlatforms"
                title="Click to show/hide platforms"
            >
                Platforms <i class="fas fa-chevron-down" ml-2></i>
            </h4>
            <nav class="mt-2 collapse show" id="collapsePriorityPlatforms" aria-labelledby="filter-data-priority-platform">
                <div style="height: fit-content; max-height: 350px; overflow: auto">
                    <ar-textfield
                        icon="fas fa-search"
                        :class="`ml-3 mr-3 mb-0`"
                        placeholder="Search Platforms"
                        v-model="data_priority_filters.platform.search"
                    />
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li class="nav-item a-nav-filter-item">
                            <div class="custom-control custom-checkbox disabled">
                                <input class="custom-control-input" type="checkbox" name="prioPlatform" :id="`prioPlatform-all`" :checked="!data_priority_filters.platform.selected.length" disabled>
                                <label :for="`prioPlatform-all`" class="custom-control-label"> All Platforms </label>
                            </div>
                        </li>
                        <li class="nav-item a-nav-filter-item" v-for="(platform, index) in filtered_prio_platforms" :key="index"> 
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" name="prioPlatform" :id="`prio-sidebar-platform-${platform.value}`"
                                        v-model="data_priority_filters.platform.selected" :value="platform.value" @change="filterDataPriorityByPlatforms()">
                                <label :for="`prio-sidebar-platform-${platform.value}`" class="custom-control-label"> {{ platform.label }} </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
          </template>
      </template>

      <template v-if="['admincampaigns'].includes($route.name)">
            <div style="display: flex;">
                <h4
                    id="filter-required-documents"
                    style="cursor: pointer; display: flex; align-items: center;"
                    class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                    data-toggle="collapse"
                    data-target="#collapseRequiredDocuments"
                    title="Click to show/hide requierd documents filter"
                >
                    REQUIRED DOCUMENTS<i class="fas fa-chevron-down ml-2"></i>
                </h4>
                <div>
                    <div class="btn-group btn-group-toggle" data-toggle="buttons" style="width: 50%">
                        <label class="btn btn-outline-secondary font-weight-normal"
                            :class="{ 'active': $route.query.requiredDoc == '1' }" style="font-size: 12px;"
                            @click="showRequiredDocuments = '1'; changeFilterOrOrder(`${showRequiredDocuments}`, 'requiredDoc')">
                            <input type="radio" name="with_claimtype-options" id="with_claimtype-with" value="1" :checked="$route.query.requiredDoc == '1'">
                                With
                        </label>
                        <label class="btn btn-outline-secondary font-weight-normal"
                            :class="{ 'active': $route.query.requiredDoc == '0' }" style="font-size: 12px;"
                            @click="showRequiredDocuments = '0'; changeFilterOrOrder(`${showRequiredDocuments}`, 'requiredDoc')">
                                <input type="radio" name="with_claimtype-options" id="with_claimtype-without" value="0" :checked="$route.query.requiredDoc == '0'"> 
                                    Without
                        </label>
                    </div>
                </div>
            </div>
           <nav class="mt-2 collapse show" id="collapseRequiredDocuments" aria-labelledby="filter-campaign">
                <div style="min-height: 100px">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item a-nav-filter-item" v-for="(doc, index) in requiredDocuments" :key="index">
                            <div class="custom-control custom-checkbox" style="margin-left: -10px; position: relative">
                                <input class="custom-control-input" type="checkbox" name="doc" :id="`admin-sidebar--doc-${doc.value}`" 
                                    v-model="required_docs_selected" :value="doc.value" @change="filterCampaignByDocs()"
                                >
                                <label :for="`admin-sidebar--doc-${doc.value}`" class="custom-control-label">
                                    <img :src="doc.img">
                                    {{doc.label}}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
      </template>
  </div>
</template>

<script>
// import { mapState } from 'vuex';

export default {
  name: "admin-drawer",
  components: {
      ...vm.$layouts.getLayouts(['ArTextfield'])
  },
  mounted() {
    if(['admindummies'].includes(this.$route.name)) {
        this.getAdminDummyPlatforms()
    }

    this.getStatuses()
    this.getCampaigns()
    this.getAdminPlatforms()
  },
  computed: {
    excludedRoutes() {
        return ![
        'adminsellers',
        'adminweeklyreport',
        'admincampaignweeklyreport',
        'adminFeatures',
        'adminUserRestrictions',
        'adminRoleBasedRestrictions',
        'adminitemcampaigns',
        'adminitemcampaigns_classification',
        'adminitemcampaigns_itemtypes',
        'adminitemclassification',
        'adminitemclassification_itemtypes',
        'adminitemtypes',
        ]
        .includes(this.$route.name)
    },
    includedRoutes_forItemClassManagement() {
        return [
            'adminitemcampaigns',
            'adminitemcampaigns_classification',
            'adminitemcampaigns_itemtypes',
            'adminitemclassification',
            'adminitemclassification_itemtypes',
            'adminitemtypes',
        ]
        .includes(this.$route.name)
    },
    filtered_admin_platforms(){//value / label
        let platforms  = this.admin_platforms
        const searched = this.searched_admin_platform.trim()

        if(searched.trim() !== '')
            return platforms = platforms.filter(platform => platform.label.toLowerCase().includes(searched.toLowerCase()))

        return platforms
    },
    admin_cm_statuses() {
        let statuses = this.unfiltered_admin_statuses

       
        let statusOrder = ["Has Potential", "Qualified", "Monitoring", "Initial Review Completed", "Accepted", "Submitted", "Rejected", "For Internal Review", "Filed"]
        let actualStatuses = []

        statuses.forEach(s => {
            let statusIndex = 0
            if ((statusIndex = statusOrder.indexOf(s.status)) !== -1) {
                actualStatuses[statusIndex] = s
            }
        })

        return actualStatuses
    },
    filtered_prio_campaigns() {
        let campaigns = this.data_priority_filters.campaign.options
        let search = this.data_priority_filters.campaign.search

        if(search) {
            return campaigns.filter(c => c.name.toLowerCase().includes(search.toLowerCase()))
        }

        return campaigns
    },
    filtered_prio_platforms() {
        let platforms = this.data_priority_filters.platform.options
        let search = this.data_priority_filters.platform.search

        if(search) {
            return platforms.filter( p => p.label.toLowerCase().includes(search.toLowerCase()))
        }

        return platforms
    },
    admin_statuses() {
        let statuses = this.unfiltered_admin_statuses
        let statusOrder = [
            "Work in Progress",
            /**
             * Deliverable: Hide all For Rework/Reworked elements across all GUI
             * Deliverable Number: 454
             */
            /*"Recheck", Hide For Rework*/ 
            /*"Reworked", Hide Reworked*/ 
            "Researched",
            "Qualified", 
            "Accepted", 
            "Monitoring", 
            "Has Potential",
            
            /**
             * Deliverable #552: Removal of Invalid status from Artemis
             */
            "Invalid",
            
            "Rejected",
            "For Internal Review",
            "Recycled",
            "archived",
            "Submitted"
        ]
        let actualStatuses = []

        statuses.forEach(s => {
            let statusIndex = 0
            if ((statusIndex = statusOrder.indexOf(s.status)) !== -1){
                /* if(s.label == "Recheck"){
                    s.label = "For Rework"
                } */

                if (s.status === 'archived') {
                    s.status = 'Archived';
                }

                actualStatuses[statusIndex] = s
            }
        })

        return actualStatuses
    },
    filtered_whitelists_campaigns() {
        const keywords = this.whitelisted_seller_filters.campaign.search
        if (!keywords)
            return this.whitelisted_seller_filters.campaign.options

        return this.whitelisted_seller_filters.campaign.options.filter(o => o.label.toLowerCase().includes(keywords.toLowerCase()))
    },
    filtered_whitelists_platforms() {
        const keywords = this.whitelisted_seller_filters.platform.search
        if (!keywords)
            return this.whitelisted_seller_filters.platform.options

        return this.whitelisted_seller_filters.platform.options.filter(o => o.label.toLowerCase().includes(keywords.toLowerCase()))
    },
    isInFeatureAccessRoutes() {
        return ['adminFeatures', 'adminUserRestrictions', 'adminRoleBasedRestrictions'].includes(this.$route.name)
    },
  },
  data() {
    return {
    //admin_statuses: [{id:4,status:"Work in Progress"}, {id:1,status:"Researched"},{id:2,status:"For Rework"},{id:8,status:"Reworked"},{id:3,status:"Qualified"},{id:98,status:"Has Potential"},{id:99,status:"Invalid"},{id:33,status:"Rejected"}],
      admin_platforms: [],
      admin_radio_platforms:[],
      admin_campaigns:[],
      admin_user_types: [{
          value: -1,
          label: "All"
      },
      {
          value: 1,
          label: "Researcher"
      },
      {
          value: 2,
          label: "QA"
      },
      {
          value: 3,
          label: "Lawyer"
      },
      {
          value: 4,
          label: "Administrator"
      },
      {
          value: 5,
          label: "Campaign Lead"
      },
      {
          value: 6,
          label: "Client"
      },
      {
          value: 7,
          label: "Super Admin"
      }],
      searched_admin_platform: "",
      show_whitelist: false,
      unfiltered_admin_statuses: [],
      whitelisted_seller_filters: {
          campaign: {
              selected: [],
              search: '',
              options: []
          },
          platform: {
              selected: [],
              search: '',
              options: []
          }
      },
      data_priority_filters: {
        status: {
            selected: [],
            search: '',
            options: []
        },
        platform: {
            selected: [],
            search: '',
            options: []
        },
        campaign: {
            selected: [],
            search: '',
            options: []
        }
      },
      reasons_filters_types: [
        {
            value: -1,
            label: 'All'
        },
        {
            value: 0,
            label: 'Stand Alone'
        },
        {
            value: 2,
            label: 'With Sub-reasons'
        },
        {
            value: 1,
            label: 'Others'
        },
      ],
      for_rework_filters: [
        {
            value: 1,
            label: 'Notify Team Lead',
            choices: [
                {
                    value: 0,
                    label: 'All',
                },
                {
                    value: 1,
                    label: 'Yes',
                },
                {
                    value: 2,
                    label: 'No',
                },
            ],
        },
        {
            value: 2,
            label: 'Severity Level',
            choices: [
                {
                    value: 0,
                    label: 'All',
                },
                {
                    value: 1,
                    label: 'Crucial',
                },
                {
                    value: 2,
                    label: 'Major',
                },
                {
                    value: 3,
                    label: 'Minor',
                },
            ],
        },
      ],
        classification_management_showAll: [{
            label: 'Campaigns',
            value: 0,
        }, {
            label: 'Classes',
            value: 1,
        }, {
            label: 'Item Types',
            value: 2,
        }],
        lfc_validation: [
            {
                value: -1,
                label: 'All'
            },
            {
                value: 1,
                label: 'LFC-Specific'
            },
        ],
        hideEmptyCampaigns: false,
        requiredDocuments: [
            {
                label: 'Approved Complaint',
                value: 'Approved Complaint',
                img: '/static/svg/case-management-modification/Approved Complaint.svg'
            },
            {
                label: 'Approved Declaration',
                value: 'Approved Declaration',
                img: '/static/svg/case-management-modification/Approved Declaration.svg'
            },
            {
                label: 'Approved and Signed Agreement',
                value: 'Approved and Signed Agreement',
                img: '/static/svg/case-management-modification/Approved and Signed Agreement.svg'
            },
            {
                label: 'Approved Affiliate Document',
                value: 'Approved Affiliate Document',
                img: '/static/svg/case-management-modification/Approved Affiliate Document.svg'
            }
        ],
        showRequiredDocuments: '1',
        required_docs_selected : []
    }
  },
  methods: {
    resetWhitelistedFilter(key = 'campaign') {
        this.whitelisted_seller_filters[key].selected = []
    },
    resetRequiredDocsFilter() {
        this.required_docs_selected = []
        this.changeFilterOrOrder('-1', 'docUploaded')
    },
    changeFilterOrOrder(val, type) {
        let query = {...this.$route.query};
        query[type] = val;
        this.$router.push({name: this.$route.name, query: query});
    },
    setHideEmptyCampaigns() {
        this.hideEmptyCampaigns = !this.hideEmptyCampaigns
        this.$router.push({'query': Object.assign({},this.$route.query,{'hideEmpty': this.hideEmptyCampaigns})})
    },
    getAdminDummyPlatforms(){//Platforms fetched are based on the listed platforms in Dummy Accounts
      const headers = this.$session.get("admin_headers")
      this.$http.get("dummy_accounts/platforms",headers)
        .then(response=>{
            if(response.status == 200){
                let platforms = response.data.data.map(platform=> platform.platform_name.split(".")[0].toLowerCase().replace(/\s/g, "")).filter(f=> f !== "")
                this.admin_radio_platforms = platforms.filter((item, index) => platforms.indexOf(item) === index).map(platform=> platform.charAt(0).toUpperCase() + platform.slice(1)).sort()
            }
        })
        .catch(err=>{
            console.log(err)
            this.$parent.showSnackbar("Something went wrong while fetching Dummy Platforms. Please contact system admin.",false, "invalid", "fas fa-times-circle")
        })
    },
    getAdminPlatforms(){
        const headers = this.$session.get("admin_headers")
        this.$http.get("platforms?page_size=1000", headers)
        .then(response=>{
            if(response.status == 200){
                let result = response.data
                // this.admin_platforms = result.data.map(platform=>({"value":platform.id,"label":platform.name}))
                this.data_priority_filters.platform.options = result.data.map(platform => ({ "value": platform.id, "label": platform.name }))
            }
        })
        .catch(err=>{
          console.log(err)
          this.$parent.showSnackbar("Something went wrong. Please contact system admin.",false, "invalid", "fas fa-times-circle")
        })
    },
    getAdminCampaigns(){
        const headers = this.$session.get("admin_headers")
        this.$http.get('seller_campaigns?page_size=1000', headers)
        .then(response=>{
          if(response.status == 200){
              let result = response.data
              this.admin_campaigns = result.data.map(campaign => ({"id":campaign.id,"name":campaign.name}))
          }
        })
        .catch(err=>{
            console.log(err)
            this.$parent.showSnackbar("Something went wrong. Please contact system admin",false,"invalid","fas fa-times-circle")
        })
    },
    getStatuses() {
        const headers = this.$session.get('admin_headers');

        if (headers === undefined) {
            return;
        }

        this.$http.get('qflag?page_size=10000', headers)
            .then(({ data }) => {
                this.unfiltered_admin_statuses = data.data.map(status => ({id: status.id, status: status.label}))
                this.data_priority_filters.status.options = data.data
            })
            .catch(err=>{
                console.log(err)
                this.$parent.showSnackbar("Something went wrong. Please contact system admin",false,"invalid","fas fa-times-circle")
            })
    },
    getCampaigns() {
        let headers = {headers: {...this.$session.get('admin_headers').headers}};
        headers.headers['Active-Only'] = 1;

        this.$http.get('campaigns-only?page_size=1000', headers)
            .then(({ data }) => {
                this.data_priority_filters.campaign.options = data.data.map(campaign => ({id: campaign.id, name: campaign.name}))
            })
            .catch(err => {
                console.log(err)
                this.$parent.showSnackbar("Something went wrong. Please contact system admin",false,"invalid","fas fa-times-circle")
            })
    },
    filterDataPriorityByPlatforms() {
        this.$router.push({"query":Object.assign({},this.$route.query,{"platforms":this.data_priority_filters?.platform?.selected})})
    },
    filterDataPriorityByCampaigns() {
        this.$router.push({"query":Object.assign({},this.$route.query,{"campaigns":this.data_priority_filters?.campaign?.selected})})
    },
    filterDataPriorityByStatuses() {
        this.$router.push({"query":Object.assign({},this.$route.query,{"statuses":this.data_priority_filters?.status?.selected})})
    },
    filterCampaignByDocs() {
        this.$router.push({"query":Object.assign({}, this.$route.query,{"docUploaded":this.required_docs_selected})})
    }
  },
  watch: {
    '$route.name': {
      handler() {
        if(this.$session.has("admin")){
            if(this.$route.name.includes('admindummies')){
                this.getAdminDummyPlatforms()
            }else if(['adminreasons'].includes(this.$route.name)){
                if(this.$route.query.status == null)
                this.$router.push({"query":Object.assign({},this.$route.query,{"status":this.admin_statuses[0].id, "type":this.reasons_filters_types[0].value,"is_for_clients":this.lfc_validation[0].value})})
            }else if(['admindatapriority'].includes(this.$route.name)) {
                if(this.$route.query.status == null)
                this.$router.push({"query":Object.assign({}, this.$route.query,{"status": -1})})
            } else if(['admincampaignweeklyreport'].includes(this.$route.name)){
                if(this.$route.query.hideEmpty == null) {
                    this.$router.push({"query":Object.assign({}, this.$route.query,{"hideEmpty": false})})
                }
            }
            // TEMPORARILY HIDDEN TO BE EXCLUDED IN BETA TESTING
            // else if(['admincampaigns'].includes(this.$route.name)){
            //     if(this.$route.query.requiredDoc == null) {
            //         this.$router.push({"query":Object.assign({}, this.$route.query,{"requiredDoc": '1'})})
            //     }
            // }
        }
      },
      deep: true
    },
    active_status(){//push clicked admin status in route
        this.$store.dispatch("setAdmFdStatus",{"status":this.active_status})
        this.$router.push({query:{"status":this.active_status}})
    },
    admin_campaigns(){
        if(this.admin_campaigns.length > 0 && this.$route.query.campaign == null){
            this.$router.push({"query": Object.assign({},this.$route.query,{"campaign":this.admin_campaigns[0].id})})
        }
    },
    admin_platforms(){
        if(this.admin_platforms.length > 0 && this.$route.query.platform == null){
            this.$router.push({"query":Object.assign({},this.$route.query,{"platform":this.admin_platforms[0].value})})
        }
    },
    '$route.query.whitelist'(val) {
        if(val === undefined)
            this.show_whitelist = false
    },
    show_whitelist(val) {
        if(this.$route.name === 'adminsellers') {
            this.$router.push({
                name: 'adminsellers',
                query: Object.assign({}, this.$route.query, {whitelist: +val})
            })
        }
    },
    'whitelisted_seller_filters.campaign.selected'(val) {

    },
  }
}
</script>

<style scoped>
/*
*   SIDEBAR
*/
.a-sidebar-filter {
    -webkit-transition: top .5s ease, margin .5s ease;
    transition: top .5s ease, margin .5s ease;
    box-shadow: none;
    width: 323px;
    top: 57px;
    right: 0;
}

/* .a-sidebar.a-sidebar-filter {
    padding-bottom: 60px;
} */

.a-sidebar-filter-header {
    text-transform: uppercase;
    font-weight: 500;
    color: #515365;
    font-size: 12px;
}

.a-nav-filter-item {
    padding-bottom: 11px;
    padding-left: 39px;
    padding-top: 11px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
}

.custom-control label.custom-control-label {
    color: #515365;
    font-weight: 400;
    font-size: 14px;
}
/*
*   END OF SIDEBAR
*/


</style>
